import { Link, useLocation } from "react-router-dom";
import styles from "./breadcrumbs.module.css";
import { MdHome } from "react-icons/md";
import { useSelector } from "react-redux";

export const Breadcrumbs = () => {
	const projectName = useSelector(
		(state) => state.projectAnalysisSlice.projectAnalysisData.name
	);
	const partName = useSelector(
		(state) => state.partAnalysisSlice.partAnalysisData.partName
	);

	const location = useLocation();

	// CurrentLink to navigate to the page pointed to by latestPath on click
	let currentLink = "";
	// To show the page on which we are on
	let latestPath = "";

	console.log(location);

	const crumbs = location.pathname.split("/").map((path, index, array) => {
		if (index === 0) {
			latestPath = <MdHome size={20} />;
		}

		if (index === 1) {
			if (path !== "settings") {
				if (path.length) {
					latestPath = `Project ${!projectName ? "" : ": " + projectName}`;

					currentLink += `/${path}`;
				} else {
					return null;
				}
			} else {
				latestPath = "Settings";
				currentLink += `/${path}`;
			}
		}

		if (index >= 2) {
			// const partName = location.state.partName;

			latestPath = `Part ${!partName ? "" : ": " + partName}`;

			currentLink += `/${path}`;
		}

		return (
			<>
				{path === undefined || path === null ? null : (
					<div
						className={styles.crumb}
						key={index}
					>
						<Link
							to={currentLink}
							style={{
								color: `${array.length === index + 1 ? "white" : ""}`,
							}}
						>
							{latestPath}
						</Link>
					</div>
				)}
			</>
		);
	});

	return <div className={styles.breadcrumbs}>{crumbs}</div>;
};
