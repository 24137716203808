import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/axios";

const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: null,
		isAuthenticated: true,
		token: null,
		loading: true,
		currencyType: "INR",
	},
	reducers: {
		setUser(state, action) {
			state.user = action.payload.user;
			state.isAuthenticated = true;
			state.token = action.payload.token;
			state.loading = false;
			state.currencyType = action.payload.user.currency_type;
		},
		clearUser(state) {
			state.user = null;
			state.isAuthenticated = false;
			state.token = null;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(addCurrency.fulfilled, (state, action) => {
			state.currencyType = action.payload;
		});
	},
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;

export const addCurrency = createAsyncThunk(
	"put/currency",
	async (newCurrency, thunkAPI) => {
		try {
			const response = await axios.put(
				"/api/v1/save/currency-type",
				JSON.stringify({
					newCurrency,
				}),
				{
					headers: { "Content-Type": "application/json" },
				}
			);
			if (response.status === 200) {
				console.log(response);
				return newCurrency;
			} else console.log(response);
		} catch (err) {
			console.log(err);
			const { rejectWithValue } = thunkAPI;
			return rejectWithValue(err.response.data);
		}
	}
);
