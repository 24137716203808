import { useState } from "react";

// Redux imports
import {
	addUploadedStlFiles,
	setErrors,
	setUploadingDisable,
} from "redux/Slice/StepperSlice";

// Library imports
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

// components
import {
	dataURLtoBlob,
	multiPartUploadToS3,
	uploadFileToS3,
	uploadSnapshotImageToS3,
} from "AWS/awsService";

const useFileUpload = (
	projectId,
	cardId,
	setProcessing, //
	setProgress,
	userID,
	card,
	dispatch,
	props
) => {
	const [complete, setComplete] = useState(true); // complete's initialState: true means upload process isn't running, at creation of card uploading needs to be false
	const [selectedFile, setSelectedFile] = useState(null);
	const [fileNumber, setFileNumber] = useState({
		currentFileNumber: null,
		totalFileNumber: null,
	});

	// Check if the file is valid, has proper file type, is within the file size limit
	const validateFiles = (files) => {
		const validFiles = [];

		for (const file of files) {
			const extension = file.name.split(".").pop().toLowerCase();
			console.log("files-----", file);

			if (
				(extension === "stl" || extension === "step" || extension === "stp") &&
				file.size <= 3e8
			) {
				validFiles.push(file);
			} else {
				if (file.size > 3e8) {
					toast.error(`Error: File '${file.name}' is larger than 300 MB `, {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					toast.error(
						`Error: File '${file.name}' is not an .stl, .step, or .stp file.`,
						{
							position: toast.POSITION.TOP_RIGHT,
						}
					);
				}
			}
		}

		//Remove parts when uploading multiple parts containing already uploaded parts
		// TODO: Evalutate if checking for existing file while validation loop is faster

		const parts = card[props.cardNumber].parts;
		const updatedValidFiles = validFiles.filter((file) => {
			return parts ? !parts.find((part) => part.name === file.name) : file;
		});

		return updatedValidFiles;
	};

	// Takes screenshot of .stl files and returns the data link to it
	const takeScreenshot = async (stlFile) => {
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, 800 / 600, 0.1, 1000);
		camera.position.set(100, 100, 100);

		// Add an ambient light for overall illumination
		const ambientLight = new THREE.AmbientLight(0x404040, 85);
		scene.add(ambientLight);

		// Create directional lights
		const light1 = new THREE.DirectionalLight(0xffffff, 0.01);
		const light2 = new THREE.DirectionalLight(0xffffff, 0.1);
		const light3 = new THREE.DirectionalLight(0xffffff, 0.1);
		const light4 = new THREE.DirectionalLight(0xffffff, 0.5);

		light1.position.set(-1, -1, 1).normalize();
		light2.position.set(-1, 1, -1).normalize();
		light3.position.set(1, -1, -1).normalize();
		light4.position.set(4, 1, 2).normalize();

		scene.add(light1);
		scene.add(light2);
		scene.add(light3);
		scene.add(light4);
		camera.lookAt(0, 0, 0);
		scene.background = new THREE.Color(0xb8c2cc);

		// Load Stl
		const loader = new STLLoader();
		const geometry = await new Promise((resolve) => {
			loader.load(URL.createObjectURL(stlFile), (geometry) => {
				geometry.scale(0.1, 0.1, 0.1); // Adjust the scale as needed
				const material = new THREE.MeshPhongMaterial({
					color: "#272525",
					specular: 0xffffff,
					shininess: 100,
					emissive: 0x101010,
				});
				const mesh = new THREE.Mesh(geometry, material);
				mesh.geometry.center();
				scene.add(mesh);
				resolve(geometry);
			});
		});

		const boundingBox = geometry.boundingBox;
		const maxDimension = Math.max(
			boundingBox.max.x - boundingBox.min.x,
			boundingBox.max.y - boundingBox.min.y,
			boundingBox.max.z - boundingBox.min.z
		);
		const distance = maxDimension * 0.7;
		camera.position.set(distance, distance, distance);

		// Create a WebGLRenderer without specifying a canvas
		const renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setSize(800, 600); //Adjust size of Snapshot

		// Render the scene onto the renderer
		renderer.render(scene, camera);

		// Get the screenshot as a data URL directly from the renderer
		const dataUrl = renderer.domElement.toDataURL("image/png");

		return dataUrl;
	};

	// Upload snapshot of the part to S3
	const uploadSnapshotToS3 = async (
		file,
		snapshotDataURL,
		projectId,
		userCardId,
		setProgress,
		userID
	) => {
		const snapshotBlob = dataURLtoBlob(snapshotDataURL);

		const snapshotFile = new File(
			[snapshotBlob],
			file.name.replace(".stl", "") + ".png"
		);

		return await uploadSnapshotImageToS3(
			snapshotFile,
			projectId,
			userCardId,
			setProcessing,
			setProgress,
			userID,
			"snapshot/"
		);
	};

	// Upload a part to S3, reset progress and add part details to redux
	const uploadPartToS3 = async (file, i, userCardId) => {
		setSelectedFile(file[i].name);

		setFileNumber({
			currentFileNumber: i + 1,
			totalFileNumber: file.length,
		});

		try {
			// IF File size is less than 5MB then upload it else upload in parts
			if (file.size < 5 * 1024 * 1024) {
				const s3Url = await uploadFileToS3(
					file[i],
					projectId,
					cardId,
					setProgress,
					userID
				);

				console.log(s3Url);
				// Reset the progress bar to 0
				setProgress(0);
			} else {
				const s3Url = await multiPartUploadToS3(
					file[i],
					projectId,
					cardId,
					setProgress,
					userID
				);

				console.log(s3Url);
				// Reset the progress bar to 0
				setProgress(0);
			}

			const partObj = {
				name: file[i].name,
				id: uuidv4(),
				qty: 1,
			};

			//Adds part's details in object form
			dispatch(addUploadedStlFiles({ cardId: userCardId, partObj }));
		} catch (error) {
			//TODO: Proper Error Handling
			console.log("Catch error", error);
		}
	};

	// Handles conditional error throwing regarding file
	const errorHandling = (file, event) => {
		if (file.length === 0 && event.target.files.length !== 0) {
			// If file already uploaded, show an error
			const errorMessage = "File already uploaded";
			const name = "file";

			dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
			setProcessing(false);

			return;
		} else if (file.length === 0) {
			// No file selected, show an error
			const errorMessage = "Please select a file";
			const name = "file";

			dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
			setProcessing(false);

			return;
		} else if (
			file.length +
				(card[props.cardNumber].parts != null
					? card[props.cardNumber].parts.length
					: 0) >
			10
		) {
			// Exceeded maximum file limit, show an error
			console.log("Inside else if");

			const errorMessage = "Maximum 10 files can be selected";
			const name = "file";

			dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
			setProcessing(false);

			return;
		} else {
			const name = "file";
			const errorMessage = "";

			dispatch(setErrors({ cardNumber: props.cardNumber, name, errorMessage }));
		}
	};

	// Upload the file
	const handleUpload = async (event) => {
		dispatch(setUploadingDisable(true));
		setProcessing(true);

		const file = validateFiles(event.target.files);

		errorHandling(file, event);

		// Generate snapshot for the STL files
		for (let i = 0; i < file.length; i++) {
			if (
				file[i].name.toLowerCase().endsWith(".stp") ||
				file[i].name.toLowerCase().endsWith(".step")
			) {
				console.log("Continue--");

				continue;
			}

			console.log("NOt continue");
			const snapshotDataURL = await takeScreenshot(file[i]);

			// Upload the snapshot image to S3
			try {
				// Returns link to snapshot
				const snapshotS3Url = await uploadSnapshotToS3(
					file[i],
					snapshotDataURL,
					projectId,
					cardId,
					setProgress,
					userID
				);
			} catch (error) {
				console.log("Error uploading snapshot:", error);
			}
		}
		setProcessing(false);

		const userCardId = props.id;
		setComplete(false);

		// Upload each part sequentially
		for (let i = 0; i < file.length; i++) {
			//handles whole or multipart upload
			await uploadPartToS3(file, i, userCardId);
		}

		setComplete(true);
		dispatch(setUploadingDisable(false));

		// Show a toast on successfull uploading of a file
		toast.success("File Uploaded Sucessfully !", {
			position: toast.POSITION.TOP_RIGHT,
		});
	};

	return { handleUpload, selectedFile, complete, fileNumber };
};

export default useFileUpload;
