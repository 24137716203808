import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./review.module.css"; // Import the CSS module
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { trimPartName } from "Utils/utils";

const Review = () => {
	const materials = useSelector(
		(state) => state.stepperSlice.project.materials
	);

	// Add state to keep track of the collapsed state of each material
	const [collapsedMaterials, setCollapsedMaterials] = useState({});

	// Function to toggle the collapse state of a material on arrow click
	const toggleCollapse = (materialId) => {
		setCollapsedMaterials((prev) => ({
			...prev,
			[materialId]: !prev[materialId], // Toggle the value
		}));
	};

	return (
		// <div>
		<div className={styles["material-tables"]}>
			{materials.map((material) => (
				<div key={material.id}>
					<h2
						// className={(styles.head2, styles.clickable)}
						className={`${styles.head2} ${styles.clickable}`}
						onClick={() => toggleCollapse(material.id)}
					>
						{material.material}
						{collapsedMaterials[material.id] ? (
							<MdKeyboardArrowRight />
						) : (
							<MdKeyboardArrowDown />
						)}
					</h2>
					{!collapsedMaterials[material.id] && (
						<table className={styles["material-table"]}>
							<thead>
								<tr>
									<th>Name</th>
									<th>Quantity</th>
									<th>Application</th>
								</tr>
							</thead>
							<tbody>
								{material.parts.map((part) => (
									<tr key={part.id}>
										<td>{trimPartName(part.name, 50)}</td>
										<td>{part.qty}</td>
										<td
											style={{
												color: part.application ? "inherit" : "grey",
												fontStyle: part.application ? "normal" : "italic",
												// fontWeight: part.application ? "normal" : "500",
											}}
										>
											{part.application
												? trimPartName(part.application, 20)
												: "--Not selected--"}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			))}
		</div>
		// </div>
	);
};

export default Review;
