/* global Autodesk */
import { Component } from "react";
import "./viewer.css";
import { getAccessTokenFromLambda } from "api/viewer";
import { SelectionExtension } from "./SelectionExtension";
import { LoadingScreen } from "components";

class ViewerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
    this.viewer = null;
  }

  async componentDidMount() {
    const { viewerRef, urn, modelGuid, extensions } = this.props;
    const token = await getAccessTokenFromLambda();
    // configuring viewer to use SVF2 version
    const options = {
      // env: "AutodeskProduction2",
      // api: "streamingV2",
      getAccessToken: async (callback) => {
        callback(token, 9999);
      },
    };
    const that = this;

    (async function () {
      const viewer = await initViewer(viewerRef.current, options, extensions);
      loadModel(viewer, urn, modelGuid)
        .then(() => that.setState({ isLoading: false }))
        .catch((e) => {
          that.setState({ isLoading: false });

          that.props.onModelError(e);
        });

      viewer.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        async () => {
          // to access viewer from console
          window.asp_viewer = viewer;

          that.props.onModelLoaded(viewer);
          that.viewer = viewer;
        },
      );
    })();
  }

  componentWillUnmount() {
    if (this.viewer) {
      // Autodesk.Viewing.shutdown();
      this.viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT);
      this.viewer.finish();
      this.viewer = null;
      window.asp_viewer = null;
    }
  }

  render() {
    // Loader untill part is loaded
    return (
      <div className="loaderContainer">
        <LoadingScreen isLoading={this.state.isLoading} />
      </div>
    );
  }
}

ViewerComponent.defaultProps = {
  extensions: [],
};

export { ViewerComponent };

// Support functions for the viewer

export function initViewer(container, options, extensions) {
  return new Promise(function (resolve, reject) {
    Autodesk.Viewing.Initializer(options, function () {
      const viewer = new Autodesk.Viewing.GuiViewer3D(container, {
        extensions,
      });

      viewer.start();
      resolve(viewer);
    });
  });
}

export function loadModel(viewer, urn, guid) {
  return new Promise(function (resolve, reject) {
    function onDocumentLoadSuccess(doc) {
      const viewable = guid
        ? doc.getRoot().findByGuid(guid)
        : doc.getRoot().getDefaultGeometry();
      resolve(viewer.loadDocumentNode(doc, viewable));
    }

    function onDocumentLoadFailure(code, message, errors) {
      reject({ code, message, errors });
    }

    Autodesk.Viewing.Document.load(
      "urn:" + urn,

      onDocumentLoadSuccess,
      onDocumentLoadFailure,
    );
  });
}
