import {
	MdExitToApp,
	MdLogout,
	MdMenu,
	MdOutlineClose,
	MdPerson,
	MdSpaceDashboard,
} from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import small_logo from "assets/newImages/NewLogo.png";
import logo2 from "assets/newImages/ame_second.png";
import styles from "./sidebar.module.css";
import appRoutes from "./routes/appRoutes";
import { useState } from "react";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { removeAllTokens } from "auth/auth.service";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export const Sidebar = ({ show, setShow }) => {
	const [activeClass, setActiveClass] = useState(null);
	const componentRef = useRef(null);
	const location = useLocation();

	const { user } = useSelector((state) => state.authSlice);

	const handleDashboardRedirect = () => {
		window.location.href = "https://dev-dashboard.intechadditive.com";
	};

	const handleLogout = () => {
		removeAllTokens();
		window.location.href = "https://intechadditive.us.auth0.com/v2/logout";
	};

	const DropdownItem = ({ Icon, text, onClick }) => {
		return (
			<div
				className={`${styles.dropdownItem} ${styles.listItem}`}
				onClick={onClick}
			>
				<span style={{ paddingLeft: "0.8rem" }}>{<Icon />}</span>
				{text}
			</div>
		);
	};

	// Remove active class if event is outside the component
	const toggleProfileDropdown = useCallback(
		(event) => {
			// Check whether the click target is within the component or not
			if (
				componentRef.current &&
				!componentRef.current.contains(event.target)
			) {
				setActiveClass(null);
			}
		},
		[componentRef, activeClass]
	);

	const toggleActiveClass = (element) => {
		if (activeClass === null) {
			setActiveClass(element);
		} else if (activeClass === element) {
			setActiveClass(null);
		} else {
			setActiveClass(element);
		}
	};

	//Add event listner to remove active class
	useEffect(() => {
		// Add event listener to window object when the component mounts
		if (activeClass) {
			window.addEventListener("mousedown", toggleProfileDropdown);
		} else {
			window.removeEventListener("mousedown", toggleProfileDropdown);
		}

		// Remove event listener when the component unmounts
		return () => {
			window.removeEventListener("mousedown", toggleProfileDropdown);
		};
	}, [toggleProfileDropdown]);

	//TO Close Dashboard Automatically on Navigation
	useEffect(() => {
		setShow(false);
	}, [location.pathname]);

	return (
		<>
			<header
				className={`${styles.header} ${show ? styles.spaceToggle : null}`}
			>
				<div
					style={{ display: "flex" }}
					className={styles.toggleContainer}
				>
					<div
						className={styles.headerToggle}
						onClick={() => setShow(!show)}
					>
						{show ? <MdOutlineClose size={25} /> : <MdMenu size={25} />}
					</div>

					<hr className={styles.separatingLine} />

					<div className={styles.breadcrumbsContainer}>
						<Breadcrumbs />
					</div>
				</div>

				<div
					ref={componentRef}
					className={styles.profileContainer}
				>
					{/* Toggler */}
					<h3
						className={styles.userProfileContainer}
						onClick={() => {
							toggleActiveClass("profile");
						}}
					>
						{user?.firstName[0].toUpperCase()}
					</h3>

					{/* Toggled Dropdown */}
					{activeClass === "profile" && (
						<div
							className={`${styles.profileDropdown} ${
								activeClass === "profile" ? styles.open : ""
							}`}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "1rem",
									padding: "1rem",
									textAlign: "center",
									fontSize: "1.6rem",
									fontWeight: "500",
								}}
							>
								<h3 className={styles.userProfileContainer}>
									{user?.firstName[0].toUpperCase()}
								</h3>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "left",
										alignItems: "flex-start",
									}}
								>
									{user?.firstName}
									<p style={{ fontSize: "12px", opacity: "0.4" }}>
										{user?.email}
									</p>
								</div>
							</div>
							<hr
								color="lightgray"
								style={{ opacity: "0.4" }}
							/>
							<DropdownItem
								text="User Dashboard"
								Icon={() => <MdSpaceDashboard size={20} />}
								onClick={handleDashboardRedirect}
							/>
							<DropdownItem
								text="Logout"
								Icon={() => <MdExitToApp size={20} />}
								onClick={handleLogout}
							/>
						</div>
					)}
				</div>
			</header>

			<aside className={`${styles.sidebar} ${show ? styles.show : null}`}>
				<div
					style={{
						zIndex: "100",
						position: "absolute",
						right: "10px",
						top: "10px",
						color: "white",
					}}
					className={(styles.headerToggle, styles.responsiveToggle)}
					onClick={() => setShow(!show)}
				>
					{show ? <MdOutlineClose size={25} /> : ""}
				</div>

				<nav className={styles.nav}>
					<div>
						<div className={styles.navLogo}>
							<div
								className={`${styles.closeNav} ${styles.fadeInImage} ${styles.fitImage}`}
							>
								<Link
									to="/"
									className={`${styles.fitImage}`}
								>
									<img
										src={small_logo}
										alt="logo"
									/>
								</Link>
							</div>

							<div
								className={
									show
										? `${styles.openNav} ${styles.fadeInImage} `
										: styles.closeNav
								}
							>
								{show ? (
									<Link
										to="/"
										className={`${styles.fitImage}`}
									>
										<img
											src={logo2}
											alt="logo"
										/>
									</Link>
								) : (
									<div></div>
								)}
							</div>
						</div>
						{/* Nav Routes */}
						<div className={styles.navList}>
							{appRoutes.map((navItem, index) => (
								<div key={index}>{navItem}</div>
							))}
							<ReactTooltip
								id="dashboard-tooltip"
								place="right"
								style={{
									backgroundColor: "#454545",
									opacity: `${!show ? 1 : 0}`,
									fontSize: "12px",
								}}
							/>
							<ReactTooltip
								id="settings-tooltip"
								place="right"
								style={{
									backgroundColor: "#454545",
									opacity: `${!show ? 1 : 0}`,
									fontSize: "12px",
								}}
							/>
						</div>
					</div>
				</nav>
			</aside>
		</>
	);
};
