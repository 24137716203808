import React, { useState } from "react";
import styles from "./infoPopup.module.css"; // Import your CSS module for styling
import { useDispatch, useSelector } from "react-redux";
import { clearStepper, setIsInfoPopUpOpen } from "redux/Slice/StepperSlice";

export const InfoPopup = ({ onClose, heading, message }) => {
	const [isVisible, setIsVisible] = useState(true);
	const InfoPopUpOpen = useSelector(
		(state) => state.stepperSlice.InfoPopUpOpen
	);
	const dispatch = useDispatch();
	const handleClose = () => {
		// setIsVisible(false);
		dispatch(setIsInfoPopUpOpen(false));
		dispatch(clearStepper());
	};

	return (
		<div className={`${styles.modal} ${InfoPopUpOpen ? styles.show : ""}`}>
			<div className={styles.modalContent}>
				<div className={styles.tickContainer}>
					<div className={styles.tickIcon}> </div>
				</div>
				<h2 className={styles.heading}>{heading}</h2>
				<p className={styles.message}>{message}</p>
				<button
					className={styles.closeButton}
					onClick={handleClose}
				>
					OK
				</button>
			</div>
		</div>
	);
};
