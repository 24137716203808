import styles from "./projectList.module.css";
import {
	MdBookmarkAdd,
	MdBookmarkBorder,
	MdDelete,
	MdError,
	MdSync,
} from "react-icons/md";
import {
	bookmarkProject,
	setSelectedJobToDelete,
} from "redux/Slice/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { clearProjectAnalysis } from "redux/Slice/ProjectAnalysisSlice";

const ProjectList = ({ project, pending, error }) => {
	const projectsList = useSelector((state) => state.projectSlice.projectsList);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Function to handle bookmark toggle
	const handleBookmarkToggle = (id) => {
		// Find the project to be bookmarked in the current list
		const projectToUpdate = projectsList?.find((project) => project.id === id);

		// Empty state handling
		if (!projectToUpdate) {
			console.error("Project not found");
			return;
		}

		// Update the bookmark status
		const updatedProject = {
			...projectToUpdate,
			isBookmarked: !projectToUpdate.isBookmarked,
		};

		dispatch(bookmarkProject({ id, updatedProject }));
	};

	return (
		<div
			className={styles["list-view"]}
			// Pending checks if the project is pending or fulfilled
			style={{ cursor: `${pending ? "arrow" : "pointer"}` }}
			onClick={() => {
				if (!pending) {
					//Clearing state before fetching new data so no collision
					dispatch(clearProjectAnalysis());

					navigate(`${project.id}`, { state: { projectName: project.name } });
				}
			}}
		>
			<ul>
				<li
					key={project.id}
					style={{
						// background: error ? "#FFA6A9" : "",
						// opacity: error ? 0.3 : "",
						outline: error ? "1px solid red" : "",
					}}
				>
					<div className={styles["project-info"]}>
						<span className={styles["project-name"]}>{project.name}</span>

						<span className={styles["project-date"]}>
							{!error ? (
								<p>Date: {project.date}</p>
							) : (
								<p style={{ paddingRight: "3.6rem" }}>Date: {project.date}</p>
							)}
						</span>
					</div>

					<div className={styles["del-btn"]}>
						{/* Bookmark Icon */}
						{project.isBookmarked ? (
							<MdBookmarkAdd
								className={styles["bookmark-icon"]}
								onClick={(e) => {
									e.stopPropagation();
									handleBookmarkToggle(project.id);
								}}
							/>
						) : (
							<div>
								{!error && (
									<MdBookmarkBorder
										className={styles["bookmark-icon"]}
										onClick={(e) => {
											e.stopPropagation();
											handleBookmarkToggle(project.id);
										}}
									/>
								)}
							</div>
						)}

						{pending ? (
							<div
								style={{
									width: "7.35rem",
									// width: project.isBookmarked ? "7.35rem" : "9.5rem",
									display: "flex",
									alignItems: "center",
									gap: "0.5rem",
									fontSize: "12px",
									color: "darkgrey",
								}}
							>
								{!error && <MdSync size={20} />}

								{error ? (
									<div
										style={{
											display: "flex",
											gap: "0.4rem",
											alignItems: "center",
										}}
									>
										<p
											style={{
												color: "red",
												fontWeight: "bold",
												display: "flex",
												gap: "0.5rem",
												alignItems: "center",
											}}
										>
											Error
											<MdError size={20} />
										</p>

										<MdDelete
											// className={styles["delete-button"]}
											onClick={(e) => {
												e.stopPropagation();
												dispatch(setSelectedJobToDelete(project.id));
											}}
											size={22}
											color={"black"}
										/>
									</div>
								) : (
									<p>Pending...</p>
								)}
							</div>
						) : (
							<button
								className={styles["delete-button"]}
								onClick={(e) => {
									e.stopPropagation();
									dispatch(setSelectedJobToDelete(project.id));
								}}
							>
								<span>
									<MdDelete size={15} />
									Delete
								</span>
							</button>
						)}
					</div>
				</li>
			</ul>
		</div>
	);
};

export default ProjectList;
