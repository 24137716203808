import React from "react";
// import "./button.css";
import styles from "./button.module.css";
export const CustomBtn = ({
	handleClick,
	buttonText,
	buttonIcon,
	color,
	padding,
	width,
}) => {
	return (
		<button
			style={{ backgroundColor: color, padding: padding, width: width }}
			onClick={handleClick}
			className={styles.createProjectBtn}
		>
			{buttonIcon && <span className={styles.buttonIcon}>{buttonIcon}</span>}
			{buttonText}
		</button>
	);
};
