import styles from "./loadingScreen.module.css";
import { SpinnerLoader } from "components";

export const LoadingScreen = ({ isLoading }) => {
	if (!isLoading) {
		return null; // Don't render anything if the modal is not loading
	}

	return (
		<div className={styles.modal}>
			<div className={styles.modalContent}>
				<div className={styles.loadingContainer}>
					<SpinnerLoader
						size="30px"
						color="blue"
					/>

					<p
						style={{
							color: "black",
						}}
					>
						Please Wait...
					</p>
				</div>
			</div>
		</div>
	);
};
