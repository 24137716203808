import React, { useState } from "react";
import styles from "./settings.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addCurrency } from "redux/Slice/AuthSlice.js";
import { currencyConversion } from "Utils/utils";

export const Settings = () => {
	// const { currencyType } = useSelector((state) => state.settingsSlice);
	const currencyType = useSelector((state) => state.authSlice.currencyType);
	const [selectedOption, setSelectedOption] = useState("option1");
	const [active, setActive] = useState(0);
	const dispatch = useDispatch();
	const handleOptionChange = (event) => {
		dispatch(addCurrency(event.target.value));
	};

	const currencyOptions = [
		{ value: "JPY", label: "YEN" },
		{ value: "INR", label: "INR" },
		{ value: "USD", label: "USD" },
		{ value: "EUR", label: "EUR" },
		// Add more currency options as needed
	];

	const tableData = [
		{ key: "1", column1: "Value 1", column2: "Description 1" },
		{ key: "2", column1: "Value 2", column2: "Description 2" },
		{ key: "3", column1: "Value 3", column2: "Description 3" },
		{ key: "4", column1: "Value 4", column2: "Description 4" },
		{ key: "5", column1: "Value 5", column2: "Description 5" },
		// Add more rows as needed
	];
	const conventionalMaterials = [
		{ name: "C101 (Oxygen-Free Electronic Copper)", cost: 1500 },
		{ name: "C110 (Electrolytic Tough Pitch Copper)", cost: 1200 },
		{ name: "C122 (DHP Copper)", cost: 1200 },
		{ name: "C443 (Admiralty Brass)", cost: 1000 },
		{ name: "C706 (90/10 Copper-Nickel)", cost: 800 },
		{ name: "Co-Cr-Mo (L-605)", cost: 4000 },
		{ name: "Co-Cr-W (MP35N)", cost: 4100 },
		{ name: "Co-Cr-Mo (F75)", cost: 4000 },
		{ name: "Co-Cr-Mo (ASTM F1537)", cost: 3800 },
		{ name: "Co-Ni-Cr-Mo (Rexolite)", cost: 3600 },
		{ name: "Ti-6Al-4V", cost: 8000 },
		{ name: "Ti-6Al-4V ELI", cost: 7800 },
		{ name: "Ti Grade 2", cost: 7200 },
		{ name: "Ti Grade 5", cost: 7800 },
		{ name: "Ti Grade 9", cost: 7800 },
		{ name: "Al 6061", cost: 340 },
		{ name: "Al 7075", cost: 400 },
		{ name: "Al 5083", cost: 360 },
		{ name: "Al 2024", cost: 380 },
		{ name: "Al 6063", cost: 360 },
		{ name: "SS 304", cost: 60 },
		{ name: "SS 316", cost: 60 },
		{ name: "SS 430", cost: 60 },
		{ name: "SS 201", cost: 60 },
		{ name: "SS 17-4PH", cost: 75 },
		{ name: "Tool Steel D2", cost: 80 },
		{ name: "Tool Steel A2", cost: 80 },
		{ name: "Tool Steel O1", cost: 75 },
		{ name: "Tool Steel M2", cost: 90 },
		{ name: "Tool Steel S7", cost: 75 },
		{ name: "Tool Steel S7", cost: 75 },
		{ name: "Low Carbon Steel 1018", cost: 60 },
		{ name: "Low Carbon Steel A36", cost: 60 },
		{ name: "Low Carbon Steel 1020", cost: 60 },
		{ name: "Low Carbon Steel 1045", cost: 60 },
		{ name: "Low Carbon Steel 12L14", cost: 60 },
		{ name: "Ni 200", cost: 900 },
		{ name: "Ni 625", cost: 1200 },
		{ name: "Ni 718", cost: 1300 },
		{ name: "Ni 825", cost: 1000 },
		{ name: "Ni 600", cost: 1000 },
	];

	const equivalentMaterials = [
		{ name: "Cu-Cr-zr", cost: 8000 },
		{ name: "Cobal Cromimun", cost: 6800 },
		{ name: "Ti64", cost: 25000 },
		{ name: "AlSi10Mg", cost: 4200 },
		{ name: "SS316L", cost: 2800 },
		{ name: "SS17-4PH", cost: 3000 },
		{ name: "Maraging Steel", cost: 2800 },
		{ name: "SS316L", cost: 2800 },
		{ name: "Inconel625", cost: 4000 },
		{ name: "Inconel718", cost: 4200 },
		// ... (rest of the equivalent materials)
	];

	const tabsList = ["Conventional Materials Table", "Additive Materials Table"];

	const TableRenderer = () => {
		switch (active) {
			case 0:
				return (
					<table className={styles.table}>
						<thead>
							<tr>
								<th className={styles["table-header"]}>
									Conventional Material
								</th>
								<th className={styles["table-header"]}>Cost </th>
							</tr>
						</thead>

						<tbody>
							{conventionalMaterials.map((material, index) => (
								<tr
									key={index}
									className={
										material.name === selectedOption
											? styles["selected-row"]
											: styles["table-row"]
									}
								>
									<td className={styles["table-cell"]}>{material.name}</td>
									<td className={styles["table-cell"]}>
										{currencyConversion(material.cost, currencyType)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				);
			case 1:
				return (
					<>
						{/* <h2
							style={{
								marginTop: "3rem",
								marginBottom: "3rem",
								padding: "1rem",
								backgroundColor: "var(--card-grey)",
								borderLeft: "3px solid red",
							}}
						>
							AM Material and it's cost table
						</h2> */}
						{/* Second Table: Equivalent Materials */}
						<table
							className={styles.table}
							// style={{ marginTop: "7rem" }}
						>
							<thead>
								<tr>
									<th className={styles["table-header"]}>
										Equivalent Material
									</th>
									<th className={styles["table-header"]}>Cost</th>
								</tr>
							</thead>
							<tbody>
								{equivalentMaterials.map((material, index) => (
									<tr
										key={index}
										className={
											material.name === selectedOption
												? styles["selected-row"]
												: styles["table-row"]
										}
									>
										<td className={styles["table-cell"]}>{material.name}</td>
										<td className={styles["table-cell"]}>
											{currencyConversion(material.cost, currencyType)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				);
			default:
				return null;
		}
	};

	return (
		<div className={styles["settings-container"]}>
			<h2 className={styles["settings-heading"]}>Settings</h2>
			<div className={styles["dropdown-container"]}>
				<label
					className={styles["dropdown-label"]}
					htmlFor="currency dropdown"
				>
					Currency Type:
				</label>

				<select
					className={styles.dropdown}
					name="currencyType"
					value={currencyType}
					onChange={handleOptionChange}
				>
					{currencyOptions.map((option) => (
						<option
							key={option.value}
							value={option.value}
						>
							{option.label}
						</option>
					))}
				</select>
			</div>

			<div className={styles.mainContainer}>
				<div className={styles.tabsContainer}>
					{tabsList.map((tab, index) => (
						<button
							className={`${styles.tabs} ${
								active === index ? styles.active : null
							}`}
							onClick={() => {
								setActive(index);
							}}
						>
							{tab}
						</button>
					))}
				</div>
				<div className={styles.tablesContainer}>
					<TableRenderer />
				</div>
			</div>
		</div>
	);
};
