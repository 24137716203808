import Skeleton from "react-loading-skeleton";
import styles from "./leadTimeTab.module.css";

export const LeadTimeTab = ({ data }) => {
	const headers = ["Build No.", "Quantity", "Lead Time"];
	// const data = [
	// 	{ quantity: 34, leadTime: 243 },
	// 	{ quantity: 500, leadTime: 243 },
	// 	{ quantity: 248, leadTime: 243 },
	// 	{ quantity: 154, leadTime: 243 },
	// 	{ quantity: 1400, leadTime: 243 },
	// 	{ quantity: 1231, leadTime: 243 },
	// ];
	return (
		<div className={styles.mainContainer}>
			{/* Table Container added for Scroll of the table */}
			<div className={styles.tableContainer}>
				<table className={styles.table}>
					<thead>
						<tr>
							{headers.map((header, index) => (
								<th key={index}>{header}</th>
							))}
						</tr>
					</thead>
					<tbody style={{ textAlign: "center" }}>
						{data ? (
							data.map((rowData, index) => (
								<tr>
									<td>{index + 1}</td>
									<td>{rowData.quantity} </td>
									<td>{rowData.leadTime} hours </td>
								</tr>
							))
						) : (
							<tr>
								<td>
									<Skeleton width={"15rem"} />
								</td>
								<td>
									<Skeleton width={"15rem"} />
								</td>
								<td>
									<Skeleton width={"15rem"} />
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};
