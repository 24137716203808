import { MdOutlineError } from "react-icons/md";
import styles from "./homeError.module.css";
import { useDispatch } from "react-redux";
import { fetchProjects } from "redux/Slice/ProjectSlice";
// import { Navigate, useNavigate } from "react-router";

export const HomeError = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.errorTryAgain}>
      <button
        onClick={async () => {
          await dispatch(fetchProjects());
          window.location.reload();
        }}
      >
        <MdOutlineError size={25} /> Try Again
      </button>
      <p>Oops! An error occurred while fetching projects. Please try again.</p>
    </div>
  );
};
