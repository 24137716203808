import AWS from "aws-sdk";
import axios from "axios";

// AWS S3 env variables
const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_AWS_REGION;

// Autodesk Forge OSS env variables
const OSS_CLIENT_ID = process.env.REACT_APP_OSS_CLIENT_ID;
const OSS_CLIENT_SECRET = process.env.REACT_APP_OSS_CLIENT_SECRET;
const OSS_BUCKET = process.env.REACT_APP_OSS_BUCKET_NAME;
const OSS_REGION = process.env.REACT_APP_OSS_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadFileToS3 = (
  file,
  projectId,
  userCardId,
  setProgress,
  userID,
) => {
  return new Promise((resolve, reject) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${userID}/project-${projectId}/material-${userCardId}/` + file.name,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          reject(err);
          console.log(err);
        } else {
          const urlParams = { Bucket: S3_BUCKET, Key: params.Key };
          const s3Url = myBucket.getSignedUrl("getObject", urlParams);
          resolve(s3Url);
        }
      });
  });
};

export const multiPartUploadToS3 = (
  file,
  projectId,
  userCardId,
  setProgress,
  userID,
) => {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: `${userID}/project-${projectId}/material-${userCardId}/` + file.name,
    };

    myBucket.createMultipartUpload(params, (err, data) => {
      if (err) {
        reject("Error creating multipart upload");
        console.error("Error creating multipart upload:", err);
        return;
      }

      const { UploadId } = data;
      const partSize = 5 * 1024 * 1024; // 5MB
      const parts = Math.ceil(file.size / partSize);
      const uploadedParts = [];
      const progressArray = [];

      const uploadPart = (partNumber, start, end) => {
        const uploadPartParams = {
          ...params,
          UploadId,
          PartNumber: partNumber,
          Body: file.slice(start, end),
        };

        myBucket
          .uploadPart(uploadPartParams, (err, data) => {
            if (err) {
              reject("Error uploading part:", err);
              console.error("Error uploading part:", err);
              return;
            }

            uploadedParts.push({
              ETag: data.ETag,
              PartNumber: partNumber,
            });

            if (uploadedParts.length === parts) {
              completeMultipartUpload();
            }
          })
          .on("httpUploadProgress", ({ loaded, total }) => {
            const partProgress = Math.floor((loaded / total) * 100);
            progressArray[partNumber] = partProgress;
            setProgress(
              (
                progressArray.reduce((prev, curr) => (prev += curr), 0) / parts
              ).toFixed(1),
            );
          });
      };

      for (let i = 0; i < parts; i++) {
        const start = i * partSize;
        const end = Math.min(start + partSize, file.size);
        uploadPart(i + 1, start, end);
      }

      const completeMultipartUpload = () => {
        uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber);
        const paramsMultiplePartsUpload = {
          ...params,
          UploadId,
          MultipartUpload: {
            Parts: uploadedParts,
          },
        };

        myBucket.completeMultipartUpload(
          paramsMultiplePartsUpload,
          (err, data) => {
            if (err) {
              reject("Unable to complete upload", err);
              console.error("Unable to complete upload", err);
              return;
            }

            const urlParams = { Bucket: S3_BUCKET, Key: params.Key };
            const s3Url = myBucket.getSignedUrl("getObject", urlParams);
            resolve(s3Url);
          },
        );
      };
    });
  });
};

export const deleteFilesFromS3 = async (projectId, userCardId, userID) => {
  const params = {
    Bucket: S3_BUCKET,
    Prefix: `${userID}/project-${projectId}/material-${userCardId}/`,
  };

  const response = await myBucket.listObjectsV2(params).promise();
  const objectsToDelete = response.Contents.map((object) => ({
    Key: object.Key,
  }));

  if (objectsToDelete.length > 0) {
    const deleteParams = {
      Bucket: S3_BUCKET,
      Delete: {
        Objects: objectsToDelete,
        Quiet: false,
      },
    };

    await myBucket.deleteObjects(deleteParams).promise();
    console.log("Files deleted from S3");
  }
};

const deleteProjectFromS3 = async (projectId, userID) => {
  try {
    const params = {
      Bucket: S3_BUCKET, //S3 bucket name
      Prefix: `${userID}/project-${projectId}/`, // Path to the job folder in S3
    };

    const data = await myBucket.listObjectsV2(params).promise();

    if (data.Contents.length === 0) {
      console.log(`No objects found in the job folder: ${params.Prefix}`);
      return;
    }

    const deleteParams = {
      Bucket: S3_BUCKET,
      Delete: {
        Objects: data.Contents.map(({ Key }) => ({ Key })),
        Quiet: false,
      },
    };

    data.Contents.forEach((content) => {
      deleteParams.Delete.Objects.push({ Key: content.Key });
    });

    await myBucket.deleteObjects(deleteParams).promise();

    console.log(`Job folder deleted: ${params.Prefix}`);
  } catch (error) {
    console.error("Error deleting job folder from S3:", error);
  }
};

const deleteSingleFileFromS3 = async (
  projectId,
  userCardId,
  fileName,
  userID,
) => {
  const params = {
    Bucket: S3_BUCKET,
    Key: `${userID}/project-${projectId}/material-${userCardId}/${fileName}`,
  };
  try {
    await myBucket.deleteObject(params).promise();
    console.log(`File deleted from S3: ${params.Key}`);
  } catch (error) {
    console.error("Error deleting file from S3:", error);
  }
};

// const uploadToAutodeskForgeOSS = async (file) => {
// 	try {
// 		const bucketKey = "YOUR_BUCKET_KEY";

// 		// Use Object key to add the file properly to the bucket -
// 		//  `${userID}/project-${projectId}/material-${userCardId}/` + file.name+ uuid,
// 		const objectKey = "";

// 		// Step 1: Get the authentication token
// 		const authTokenResponse = await axios.post(
// 			"https://developer.api.autodesk.com/authentication/v1/authenticate",
// 			{
// 				client_id: OSS_CLIENT_ID,
// 				client_secret: OSS_CLIENT_SECRET,
// 				grant_type: "client_credentials",
// 				scope: "data:write data:read bucket:create bucket:read",
// 			}
// 		);

// 		const accessToken = authTokenResponse.data.access_token;

// 		// Step 2: Create a bucket (if it doesn't exist)
// 		await axios.post(
// 			"https://developer.api.autodesk.com/oss/v2/buckets",
// 			{
// 				bucketKey,
// 				policyKey: "transient", // Change as needed
// 			},
// 			{
// 				headers: {
// 					Authorization: `Bearer ${accessToken}`,
// 				},
// 			}
// 		);

// 		// Step 3: Upload the file to the bucket
// 		const formData = new FormData();
// 		formData.append("file", file);

// 		await axios.post(
// 			`https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}/objects/${
// 				objectKey || file.name
// 			}`,
// 			formData,
// 			{
// 				headers: {
// 					Authorization: `Bearer ${accessToken}`,
// 					"Content-Type": "multipart/form-data",
// 				},
// 			}
// 		);

// 		console.log("File uploaded successfully.");
// 	} catch (error) {
// 		console.error("Error uploading file to Autodesk Forge OSS:", error);
// 	}
// };

export { deleteProjectFromS3, deleteSingleFileFromS3 };

export const getObjectUrlFromS3 = async (filePath) => {
  const params = {
    Bucket: S3_BUCKET,
    Key: filePath,
  };

  try {
    const signedUrl = await myBucket.getSignedUrlPromise("getObject", params);
    // console.log("got the link - ", signedUrl);
    // console.log("filepath-", filePath);
    return signedUrl;
  } catch (error) {
    console.error("Error fetching object URL from S3:", error);
    return null;
  }
};

// my link - filepath- userId/job-e6e24255-de15-47cb-a46c-5758174759ab/material-855ef5fa-cd47-4857-b10c-bfcf01c91dba/Step (1).stl
//s3 link -  filepath- userId/job-e6e24255-de15-47cb-a46c-5758174759ab/material-0aeeb797-01ec-4122-977c-fcf99ddc88f2/Step (1).stl
// aeaf675c-219d-4678-88ea-735949a6295e
// aeaf675c-219d-4678-88ea-735949a6295e/Step (1).stl

export const uploadSnapshotImageToS3 = (
  file,
  projectId,
  userCardId,
  setProcessing,
  setProgress,
  userID,
  folderName = "",
  fileName = "snapshot.png", // Default value if not provided
) => {
  console.log("second");
  return new Promise((resolve, reject) => {
    // Handle snapshot upload
    const snapshotBlob = new Blob([file], { type: "image/png" }); // Assuming snapshotFile is already a blob
    const params = {
      // ACL: "public-read",
      Body: snapshotBlob,
      Bucket: S3_BUCKET,
      // Key: `${userID}/project-${projectId}/material-${userCardId}/snapshot/snapshot.png`,
      Key:
        `${userID}/project-${projectId}/material-${userCardId}/snapshot/` +
        file.name,
      // Key: `$newImage/project-${projectId}/material-${userCardId}/snapshot/snapshot.png`,
    };
    // ... (rest of your existing snapshot upload code)
    console.log("inside if");
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          reject(err);
          console.log(err);
        } else {
          const urlParams = { Bucket: S3_BUCKET, Key: params.Key };
          const s3Url = myBucket.getSignedUrl("getObject", urlParams);
          resolve(s3Url);
          // console.log("image uploaded- ", s3Url);
        }
      });
  });
};

// Helper function to convert data URL to Blob
export function dataURLtoBlob(dataURL) {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
