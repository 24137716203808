import { configureStore } from "@reduxjs/toolkit";
import ProjectSlice from "./Slice/ProjectSlice";
import StepperSlice from "./Slice/StepperSlice";
import ProjectAnalysisSlice from "./Slice/ProjectAnalysisSlice";
import PartAnalysisSlice from "./Slice/PartAnalysisSlice";
import AuthSlice from "./Slice/AuthSlice";

const store = configureStore({
	reducer: {
		projectSlice: ProjectSlice,
		projectAnalysisSlice: ProjectAnalysisSlice,
		partAnalysisSlice: PartAnalysisSlice,
		stepperSlice: StepperSlice,
		authSlice: AuthSlice,
	},
	// middleware: (getDefaultMiddleware) =>
	// getDefaultMiddleware().concat(AuthMiddleware),
});
export default store;
