import styles from "./drawer.module.css";

// Redux states
import { useSelector } from "react-redux";

//Library imports
import { MdClose } from "react-icons/md";

// Componenets
import { Step1 } from "./Step1/Step1";
import { Step2 } from "./Step2/Step2";
import { Stepper } from "./Stepper/Stepper";
import { FolderDropdown } from "./Stepper/FolderDropdown/FolderDropdown";
import Review from "./ReviewStep/Review";

const Drawer = (props) => {
	const currentStep = useSelector((state) => state.stepperSlice.currentStep);

	const ComponentToRender = () => {
		switch (currentStep) {
			case 0:
				return <Step1 />;

			case 1:
				return <Step2 />;

			case 2:
				return <Review />;

			default:
				return null;
		}
	};

	return (
		<div className={styles.drawerContainer}>
			{/* BACKDROP */}
			<div className={props.isDrawerOpen ? styles.backdrop : ""}></div>

			<div
				className={`${styles.drawer} ${
					props.isDrawerOpen ? styles.open : styles.close
				}`}
			>
				{/* DRAWER CONTAINER TOP BAR BOTTOM STEPPER AND MIDDLE CONTENT */}
				<div className={styles.drawerNavbar}>
					{/* Enclosed close icon in button for accessiblity purposes */}
					<button
						style={{
							background: "transparent",
							border: "none",
							outline: "none",
							cursor: "pointer",
						}}
					>
						<MdClose
							className={styles.closeDrawerBtn}
							onClick={props.handleToggle}
						/>
					</button>
				</div>

				<div className={styles.drawerContent}>
					<div className={styles.stepContainer}>
						<div className={styles.step1LeftContainer}>
							<FolderDropdown />
						</div>

						{/* Rendered Step */}
						{<ComponentToRender />}
					</div>
				</div>

				<div className={styles.stepperParentContainer}>
					{/* The Step indicator at the bottom */}
					<Stepper />
				</div>
			</div>
		</div>
	);
};

export default Drawer;
