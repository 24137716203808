import { useDispatch, useSelector } from "react-redux";
import styles from "./stepper.module.css";
import {
	LoadiingScreen,
	clearStepper,
	nextStep,
	postJob,
	prevStep,
	setErrors,
	setIsDrawerOpen,
} from "redux/Slice/StepperSlice";
import { toast } from "react-toastify";
import { fetchProjects } from "redux/Slice/ProjectSlice";
export const Stepper = () => {
	const activeStep = useSelector((state) => state.stepperSlice.currentStep);
	const cards = useSelector((state) => state.stepperSlice?.project?.materials);
	const errors = useSelector((state) => state.stepperSlice.errors);
	const projecterror = useSelector(
		(state) => state.stepperSlice.projectNameError
	);
	const projectErrName = useSelector((state) => state.stepperSlice);
	const uploadingDisable = useSelector(
		(state) => state.stepperSlice.uploadingDisable
	);
	const dispatch = useDispatch();
	const validateStep1 = () => {
		if (!projectErrName.hasOwnProperty("projectNameError")) {
			dispatch(
				setErrors({
					cardNumber: -1, // Assuming the job name error is not related to a specific card
					name: "projectName",
					errorMessage: "project Name can't be blank",
				})
			);
			return false;
		}
		if (errors.length === 0 || cards.length === 0 || projecterror) {
			toast.error("Error: Please add atleast one material to move forward !", {
				position: toast.POSITION.TOP_LEFT,
			});
			// alert("Please add atleast one material to move forward");
			return false;
		}
		const keysToCheck = [
			"material",
			// "cost",
			// "currency",
			"layer_thickness",
			"file",
		]; // Define the keys you want to check
		let hasErrors = false;
		for (let i = 0; i < cards.length; i++) {
			const cardErrorObj = errors[i];

			for (const key of keysToCheck) {
				if (!cardErrorObj.hasOwnProperty(key)) {
					// Dispatch the error action for missing key
					if (key === "cost") {
						dispatch(
							setErrors({
								cardNumber: i,
								name: key,
								errorMessage: "Please enter a positive number",
							})
						);
					} else if (key === "file") {
						console.log("inside else");
						dispatch(
							setErrors({
								cardNumber: i,
								name: key,
								errorMessage: "Please select a file",
							})
						);
					} else {
						dispatch(
							setErrors({
								cardNumber: i,
								name: key,
								value: "",
								// errorMessage: "Error message for missing key",
							})
						);
					}

					hasErrors = true;
					// return false; // Return false if any key is missing
				} else if (cardErrorObj[key] !== "") {
					let flag = false;
					if (key === "file") {
						if (cards[i].parts?.length <= 10 && cards[i].parts.length > 0) {
							dispatch(
								setErrors({
									cardNumber: i,
									name: key,
									errorMessage: "",
								})
							);
							hasErrors = false;
							flag = true;
						}
					}
					if (!flag) hasErrors = true;
				}
			}
		}

		return !hasErrors; // Return true if all keys are present
	};

	const handleNext = async () => {
		if (uploadingDisable === true) return;
		if (activeStep === 0) {
			if (validateStep1()) dispatch(nextStep());
			else return;
		} else if (activeStep === 1) {
			dispatch(nextStep());
			return;
		} else if (activeStep === 2) {
			try {
				dispatch(LoadiingScreen({ isLoading: true }));
				await dispatch(postJob());
				dispatch(LoadiingScreen({ isLoading: false }));
				// dispatch(setIsDrawerOpen(false));
				dispatch(fetchProjects());
			} catch (error) {
				console.log("received error");
			}
			// dispatch(clearStepper());
		}
		// dispatch(nextStep());
		// else if (activeStep === 1) {
		// 	dispatch(nextStep());
		// 	return;
		// } else if (activeStep === 2) {
		// 	dispatch(nextStep());
		// } else if (activeStep === 3 && status == false) {
		// 	dispatch(postJob());
		// 	dispatch(setIsDrawerOpen(false));
		// } else if (activeStep === 3 && status == true) {
		// 	dispatch(setIsDrawerOpen(false));
		// }
	};

	const handleBack = () => {
		dispatch(prevStep());
	};

	return (
		<div className={styles.stepperContainer}>
			<div className={styles.stepper}>
				<div
					className={`${styles.step} ${activeStep >= 0 ? styles.active : ""}`}
				>
					<div className={styles.stepNumber}>1</div>
					{/* <div className={styles.stepLabel}>Step One</div> */}
					<div className={styles.stepLabel}>Create Project</div>
				</div>
				<hr className={styles.divider} />
				<div
					className={`${styles.step} ${activeStep >= 1 ? styles.active : ""}`}
				>
					<div className={styles.stepNumber}>2</div>
					{/* <div className={styles.stepLabel}>Step Two</div> */}
					<div className={styles.stepLabel}>Add Quantity</div>
				</div>
				<hr className={styles.divider} />
				<div
					className={`${styles.step} ${activeStep >= 2 ? styles.active : ""}`}
				>
					<div className={styles.stepNumber}>3</div>
					{/* <div className={styles.stepLabel}>Step Three</div> */}
					<div className={styles.stepLabel}>Review</div>
				</div>
				{/* <hr className={styles.divider} /> */}
				{/* <div
					className={`${styles.step} ${activeStep >= 3 ? styles.active : ""}`}
				>
					<div className={styles.stepNumber}>4</div>
					<div className={styles.stepLabel}>Invoice</div>
				</div> */}
			</div>
			<div className={styles.stepperButtonContainer}>
				<div className={styles.stepBtn}>
					{activeStep !== 0 && activeStep !== 3 && (
						<button
							disabled={activeStep === 0}
							onClick={handleBack}
						>
							Back
						</button>
					)}
					{/* <button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </button> */}
				</div>
				<div className={styles.stepBtn}>
					<button
						onClick={handleNext}
						className={`${uploadingDisable ? styles.nextInactive : ""}`}
					>
						{/* Next */}
						{activeStep !== 2 ? "Next" : "Submit"}
						{/* {activeStep > 2 ? (status === true ? "Close" : "Save") : "Next"} */}
					</button>
				</div>
			</div>
		</div>
	);
};
