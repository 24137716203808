import { useDispatch, useSelector } from "react-redux";
import {
	MdFolder,
	MdSubdirectoryArrowRight,
	MdOutlineArrowRight,
	MdInsertDriveFile,
} from "react-icons/md";
import "./FolderDropdown.css";
import { setFolderOpen } from "redux/Slice/StepperSlice";
import { trimPartName } from "Utils/utils";
import { useEffect, useState } from "react";

export const FolderDropdown = () => {
	const dispatch = useDispatch();
	const projectName = useSelector((state) => state.stepperSlice?.project);
	const folderOpen = useSelector((state) => state.stepperSlice.folderOpen);
	const selectedFiles = useSelector(
		(state) => state.stepperSlice?.project?.materials
	);
	const [materialOpen, setMaterialOpen] = useState({});

	// Initialize materialOpen state with all materials set to true (open)
	useEffect(() => {
		if (selectedFiles) {
			const initialMaterialOpenState = {};
			selectedFiles.forEach((card) => {
				initialMaterialOpenState[card.id] = true;
			});
			setMaterialOpen(initialMaterialOpenState);
		}
	}, [selectedFiles]);

	const handleFolderToggle = () => {
		// Toggle folder open/close state
		dispatch(setFolderOpen(!folderOpen));
	};
	// Function to handle toggling the open/close state of a material
	const handleMaterialToggle = (materialId) => {
		setMaterialOpen((prevMaterialOpen) => ({
			...prevMaterialOpen,
			[materialId]: !prevMaterialOpen[materialId],
		}));
	};

	return (
		<>
			<div
				className="folder-container"
				onClick={handleFolderToggle}
			>
				<span className="folder-toggle">
					<MdOutlineArrowRight
						size={25}
						style={{
							transform: `${folderOpen ? "rotate(90deg)" : "rotate(0deg)"}`,
							transition: "200ms",
						}}
					/>
				</span>
				<MdFolder
					className="dir-btn"
					size={20}
				/>
				<span
					className="folder-name"
					style={{
						color: `${projectName.project_name.length ? "black" : "#bfbfbf"}`,
						fontWeight: `${projectName.project_name.length ? 500 : 600}`,
						textOverflow: "ellipsis" /* enables ellipsis */,
						whiteSpace: "nowrap" /* keeps the text in a single line */,
						overflow:
							"hidden" /* keeps the element from overflowing its parent */,
						fontSize: "1.5rem",
					}}
				>
					{projectName.project_name.length
						? trimPartName(projectName.project_name)
						: "Project Name"}
				</span>
			</div>

			<div
				className={`folder-content ${folderOpen ? "open" : ""}`}
				style={{ display: folderOpen ? "block" : "none" }}
			>
				{selectedFiles?.map((card, index) => {
					//Creating unique parts array from builds
					const uniqueArrayOfParts = [
						...new Set(
							card.parts?.map((part) => {
								return part.name;
							})
						),
					];
					return (
						<div key={card.id}>
							<div
								className="material-header"
								onClick={() => handleMaterialToggle(card.id)} // Handle click to toggle material open/close
							>
								<MdOutlineArrowRight
									size={20}
									style={{
										transform: `${
											materialOpen[card.id] ? "rotate(90deg)" : "rotate(0deg)"
										}`,
										transition: "200ms",
									}}
								/>
								{card.material ? (
									card.material
								) : (
									<p style={{ color: "#bfbfbf" }}>Add New Material</p>
								)}
							</div>
							<div
								className={`material-content ${
									materialOpen[card.id] ? "open" : ""
								}`}
							>
								{uniqueArrayOfParts.map((file, index1) => (
									<div
										key={index1}
										className="file-item"
										style={{
											display: materialOpen[card.id] ? "block" : "none",
										}}
									>
										<MdSubdirectoryArrowRight />
										<MdInsertDriveFile />
										<span>{trimPartName(file)}</span>
									</div>
								))}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};
