import { useSelector } from "react-redux";
import styles from "./partOverview.module.css";

import { currencyConversion } from "Utils/utils";
import Skeleton from "react-loading-skeleton";

export const PartOverview = ({ data, quantities }) => {
	const { currencyType } = useSelector((state) => state.authSlice);

	console.log(data);

	let totalQuantity = quantities?.reduce((accumulator, currentValue) => {
		return accumulator + currentValue.quantity;
	}, 0);

	return (
		<div className={styles.partCard}>
			<div className={styles.partInfoContainer}>
				<div className={styles.partInfo}>
					<div className={styles.infoRow}>
						<div>
							Part Name :{" "}
							<span>
								{data?.name ? data?.name : <Skeleton width={"15rem"} />}
							</span>
						</div>
					</div>

					<div className={styles.infoRow}>
						<div>
							The Part is :{" "}
							<span
								style={{
									color: `${
										data?.result === "printable"
											? "green"
											: data?.result === "printable with modification"
											? "orange"
											: data?.result === "non-printable"
											? "red"
											: "black"
									}`,
									textTransform: "capitalize",
								}}
							>
								{data?.result ? data?.result : <Skeleton width={"15rem"} />}
							</span>
						</div>
					</div>

					<div className={styles.infoRow}>
						<div>
							The Estimated Cost is :{" "}
							{data?.costEstimation ? (
								<span>
									{" "}
									{currencyConversion(data?.costEstimation, currencyType)}
								</span>
							) : (
								<Skeleton width={"15rem"} />
							)}
						</div>
					</div>

					<div className={styles.infoRow}>
						<div>
							Quantity :{" "}
							<span>
								{totalQuantity ? totalQuantity : <Skeleton width={"15rem"} />}
							</span>
						</div>
					</div>

					<div className={styles.infoRow}>
						<div>
							Lead time for the batch is:{" "}
							<span>
								{data?.leadTime ? (
									data?.leadTime + " hours"
								) : (
									<Skeleton width={"15rem"} />
								)}
							</span>
						</div>
					</div>

					<div className={styles.infoRow}>
						<div>
							The Recommended Printer is :{" "}
							<span>
								{data?.recommendedPrinter ? (
									data?.recommendedPrinter
								) : (
									<Skeleton width={"15rem"} />
								)}
							</span>
						</div>
					</div>

					<div className={styles.infoRow}>
						<div>
							{/* The Recommended Material is :{" "} */}
							Intech qualified AM Material is :
							<span>
								{data?.recommendedAmMaterial ? (
									data?.recommendedAmMaterial
								) : (
									<Skeleton width={"15rem"} />
								)}
							</span>
						</div>
					</div>

					<div className={styles.infoRow}>
						<div>
							Globally available AM Material is :
							<span>
								{data?.recommendedGlobalMaterial ? (
									data?.recommendedGlobalMaterial
								) : (
									<Skeleton width={"15rem"} />
								)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
