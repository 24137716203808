import { useState } from "react";
import styles from "./modal.module.css";

import { MdClose } from "react-icons/md";

export const Modal = ({
	modalButtonName,
	modalHeader,
	width = "90%",
	height = "100vh",
	top = "-5rem",
	left = "1rem",
	className,
	children,
}) => {
	const [shouldShow, setShouldShow] = useState(false);

	return (
		<div className={className}>
			{/* Modal Trigger Button */}
			<button
				className={styles.previewButton}
				onClick={() => setShouldShow(true)}
			>
				{modalButtonName}
			</button>

			{/* Modal Container */}
			{shouldShow && (
				<div
					className={`${styles.modalBackground} ${styles.fadeInPopup} ${
						shouldShow ? styles.visible : styles.hidden
					}`}
					onClick={() => {
						setShouldShow(false);
					}}
				>
					<div
						className={styles.modalBody}
						onClick={(e) => {
							e.stopPropagation();
						}}
						style={{ width, height, top, left }}
					>
						{/*Modal header  */}
						<div
							id="modalHeader"
							className={styles.headerContainer}
						>
							{/* <div className={styles.modalHeader}>{modalHeader}</div> */}

							<button
								className={styles.closeButton}
								onClick={() => {
									setShouldShow(false);
								}}
							>
								<MdClose
									size={20}
									color="red"
								/>
							</button>
						</div>

						{/*Modal body  */}
						<div className={styles.modalChildrenContainer}>{children}</div>
					</div>
				</div>
			)}
		</div>
	);
};
