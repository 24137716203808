import { useState } from "react";
import styles from "./stepOneCard.module.css";
import "react-toastify/dist/ReactToastify.css";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
	deleteCard,
	selectOption,
	setErrors,
	toggleDeletingCardIds,
} from "redux/Slice/StepperSlice";

// Library Imports
import { MdDelete, MdInfoOutline } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getRandomColor, trimPartName } from "Utils/utils";

// Components
import useFileUpload from "./useFileUpload";
import { SpinnerLoader } from "components";
import { deleteFilesFromS3 } from "AWS/awsService";

const StepOneCard = (props) => {
	const [progress, setProgress] = useState(0);
	const [processing, setProcessing] = useState(false);
	const [borderColor, setBorderColor] = useState(() => getRandomColor());

	// Redux states
	const dispatch = useDispatch();
	const errors = useSelector((state) => state.stepperSlice.errors);
	const card = useSelector((state) => state.stepperSlice.project?.materials);
	const { stepOneCardDeletingIds } = useSelector((state) => state.stepperSlice);
	const userID = useSelector((state) => state.authSlice?.user?.id);

	// Imported Component functions
	const { handleUpload, selectedFile, complete, fileNumber } = useFileUpload(
		props.projectId,
		props.id,
		setProcessing,
		setProgress,
		userID,
		card,
		dispatch,
		props
	);

	// Local states
	const materialsArray = [
		"Ni 200",
		"Ni 625",
		"Ni 718",
		"Ni 825",
		"Ni 600",
		"SS 304",
		"SS 316",
		"SS 430",
		"SS 201",
		"SS 17-4PH",
		"Tool Steel D2",
		"Tool Steel A2",
		"Tool Steel O1",
		"Tool Steel M2",
		"Tool Steel S7",
		"Low Carbon Steel 1018",
		"Low Carbon Steel A36",
		"Low Carbon Steel 1020",
		"Low Carbon Steel 1045",
		"Low Carbon Steel 12L14",
		"Al 6061",
		"Al 7075",
		"Al 5083",
		"Al 2024",
		"Al 6063",
		"Ti-6Al-4V",
		"Ti-6Al-4V ELI",
		"Ti Grade 2",
		"Ti Grade 5",
		"Ti Grade 9",
		"Co-Cr-Mo (L-605)",
		"Co-Cr-W (MP35N)",
		"Co-Cr-Mo (F75)",
		"Co-Cr-Mo (ASTM F1537)",
		"Co-Ni-Cr-Mo (Rexolite)",
		"C101 (Oxygen-Free Electronic Copper)",
		"C110 (Electrolytic Tough Pitch Copper)",
		"C122 (DHP Copper)",
		"C443 (Admiralty Brass)",
		"C706 (90/10 Copper-Nickel)",
	];

	// Checks if material is already selected
	const handleSelectChange = async (event) => {
		const { name, value } = event.target;

		// Check if the selected material is already chosen in another card
		const isMaterialSelected = card.some((cardItem, index) => {
			return index !== props.cardNumber && cardItem.material === value;
		});

		if (isMaterialSelected) {
			const errorMessage = "Material already selected in another card";
			dispatch(
				setErrors({ cardNumber: props.cardNumber, name, value, errorMessage })
			);
		} else {
			dispatch(setErrors({ cardNumber: props.cardNumber, name, value }));
		}

		handleOptionChange(event, props.id);
	};

	// Sets select value to proper card
	const handleOptionChange = (event, cardId) => {
		console.log(cardId);
		const { name, value } = event.target;

		dispatch(selectOption({ cardId, name, value }));
	};

	// Deletes all files from S3 and removes card from the ui
	const handleCardDelete = async (id) => {
		const userCardId = id;
		dispatch(toggleDeletingCardIds(userCardId));

		await deleteFilesFromS3(props.projectId, userCardId, userID);

		dispatch(deleteCard({ id }));
		dispatch(toggleDeletingCardIds(userCardId));
	};

	return (
		<div
			className={styles.step1Card}
			style={{ borderBottom: `3px solid ${borderColor}` }}
		>
			{stepOneCardDeletingIds?.includes(props.id) ? (
				<div
					style={{
						height: "36.3vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						color: "grey",
						gap: "1rem",
					}}
				>
					<SpinnerLoader
						size="30px"
						color="grey"
					/>

					<p>Deleting...</p>
				</div>
			) : (
				<>
					<div
						className={styles.deleteBtn}
						onClick={() => handleCardDelete(props.id)}
					>
						<MdDelete size={25} />
					</div>

					<div className={styles.cardNumber}>
						<p>{props.cardNumber + 1}</p>
					</div>

					<div>
						<p
							style={{
								padding: "0px 0px 4px 2px",
								fontSize: "14px",
								fontWeight: "500",
							}}
						>
							Material
						</p>

						<select
							className={styles.selectBtn}
							name="material"
							value={card[props.cardNumber].material}
							onChange={(event) => handleSelectChange(event)}
						>
							<option value="">Select an option</option>

							{materialsArray.map((material) => (
								<option value={material}>{material}</option>
							))}
						</select>

						{errors[props.cardNumber]?.material && (
							<div className="error-message">
								{errors[props.cardNumber]?.material}
							</div>
						)}
					</div>

					<div>
						<p
							style={{
								padding: "0px 0px 4px 2px",
								fontSize: "14px",
								fontWeight: "500",
							}}
						>
							Layer Thickness
						</p>

						<select
							className={styles.selectBtn}
							name="layer_thickness"
							value={card[props.cardNumber].layer_thickness}
							onChange={(event) => handleSelectChange(event)}
						>
							<option value="">Select an option</option>

							{Array.from({ length: 10 }, (_, index) => (
								<option
									key={index}
									value={(index + 1) * 10}
								>
									{(index + 1) * 10} µm
								</option>
							)).slice(1)}
						</select>

						{errors[props.cardNumber]?.layer_thickness && (
							<div className="error-message">
								{errors[props.cardNumber]?.layer_thickness}
							</div>
						)}
					</div>

					<div>
						<p
							style={{
								padding: "0px 0px 4px 2px",
								fontSize: "14px",
								fontWeight: "500",
							}}
						>
							Part Upload
						</p>

						<div
							className={styles.chooseFile}
							style={{ display: "flex", justifyContent: "space-between" }}
						>
							<input
								type="file"
								accept=".stl"
								onChange={handleUpload}
								multiple
							/>
							<div
								data-tooltip-id="card-target"
								data-tooltip-content="Please upload the part in mm with size limit of 300MB (Maximum 10 parts allowed)"
								data-tooltip-delay-show="150"
							>
								<MdInfoOutline size={22} />

								<ReactTooltip
									id="card-target"
									place="left"
									style={{
										width: "15rem",
									}}
								/>
							</div>
						</div>
					</div>

					{errors[props.cardNumber]?.file && (
						<div className="error-message">
							{errors[props.cardNumber]?.file}
						</div>
					)}

					<div className={`${styles.myDiv} ${complete ? styles.green : ""}`}>
						{processing ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "1rem",
								}}
							>
								<SpinnerLoader
									size="20px"
									color="grey"
								/>

								<p
									className={styles.processingText} // Handles Processing Files text and dot animation
									style={{ color: "var(--card-grey)" }}
								></p>
							</div>
						) : (
							<>
								{progress !== 0 && (
									<div className={`${styles.progressBar} ${styles.striped}`}>
										<div
											className={styles.progress}
											style={{ width: `${progress}%` }}
										></div>
									</div>
								)}

								{complete
									? fileNumber.currentFileNumber == null
										? ""
										: `Complete! Uploaded Files - ${fileNumber.currentFileNumber}/${fileNumber.totalFileNumber}`
									: `${trimPartName(selectedFile) + " - " + progress}%`}

								<p>
									{complete
										? ``
										: `Uploading - ${fileNumber.currentFileNumber} / ${fileNumber.totalFileNumber}`}
								</p>

								<p className={styles.infoText}>Please upload the part in mm</p>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default StepOneCard;
