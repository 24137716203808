import React, { useRef, useState } from "react";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

const ScreenshotButton = () => {
	// const hiddenCanvasRef = useRef(null);
	const [screenshotDataUrl, setScreenshotDataUrl] = useState(null);

	// const takeScreenshot = async () => {
	// 	const scene = new THREE.Scene();
	// 	const camera = new THREE.PerspectiveCamera(75, 800 / 600, 0.1, 1000);
	// 	camera.position.set(100, 100, 100);
	// 	// Add an ambient light for overall illumination
	// 	const ambientLight = new THREE.AmbientLight(0x404040);
	// 	scene.add(ambientLight);
	// 	const light = new THREE.DirectionalLight(0xffffff, 10);
	// 	light.position.set(10, 10, 10).normalize();
	// 	scene.add(light);
	// 	camera.lookAt(0, 0, 0);
	// 	scene.background = new THREE.Color(0x414345);
	// 	// scene.background = new THREE.Color(0x000000);

	// 	scene.add(light);

	// 	const loader = new STLLoader();
	// 	const geometry = await new Promise((resolve) => {
	// 		loader.load("./CONFIDENTIAL_bottom.stl", (geometry) => {
	// 			// loader.load("./Error_Cylinder.stl", (geometry) => {
	// 			// loader.load("./groot.stl", (geometry) => {
	// 			// loader.load("./Horn.stl", (geometry) => {
	// 			// loader.load("./box.stl", (geometry) => {
	// 			geometry.scale(0.1, 0.1, 0.1); // Adjust the scale as needed
	// 			const material = new THREE.MeshPhongMaterial({
	// 				color: "#5F635F",
	// 				specular: 0xffffff,
	// 				shininess: 100,
	// 				emissive: 0x101010,
	// 			});
	// 			const mesh = new THREE.Mesh(geometry, material);
	// 			mesh.geometry.center();
	// 			scene.add(mesh);
	// 			resolve(geometry);
	// 		});
	// 	});

	// 	// TEST
	// 	const boundingBox = geometry.boundingBox;
	// 	const maxDimension = Math.max(
	// 		boundingBox.max.x - boundingBox.min.x,
	// 		boundingBox.max.y - boundingBox.min.y,
	// 		boundingBox.max.z - boundingBox.min.z
	// 	);
	// 	const distance = maxDimension * 1;
	// 	camera.position.set(distance, distance, distance);
	// 	light.position.set(distance, distance, distance);
	// 	// END TEST

	// 	// Clear the canvas
	// 	const renderer = new THREE.WebGLRenderer({
	// 		canvas: hiddenCanvasRef.current,
	// 		antialias: true,
	// 	});
	// 	renderer.setSize(800, 600);
	// 	// renderer.setClearColor(0xffffff, 0);
	// 	renderer.clear();

	// 	// Render the scene onto the hidden canvas
	// 	renderer.render(scene, camera);

	// 	// Get the screenshot as a data URL
	// 	const dataUrl = hiddenCanvasRef.current.toDataURL("image/png");

	// 	// Update the state with the screenshot data URL
	// 	setScreenshotDataUrl(dataUrl);
	// };
	const takeScreenshot = async () => {
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, 800 / 600, 0.1, 1000);
		camera.position.set(100, 100, 100);

		// Add an ambient light for overall illumination
		const ambientLight = new THREE.AmbientLight(0x404040, 85);
		scene.add(ambientLight);

		// Create directional lights
		const light1 = new THREE.DirectionalLight(0xffffff, 0.01);
		const light2 = new THREE.DirectionalLight(0xffffff, 0.1);
		const light3 = new THREE.DirectionalLight(0xffffff, 0.1);
		const light4 = new THREE.DirectionalLight(0xffffff, 0.5);

		light1.position.set(-1, -1, 1).normalize();
		light2.position.set(-1, 1, -1).normalize();
		light3.position.set(1, -1, -1).normalize();
		light4.position.set(4, 1, 2).normalize();

		scene.add(light1);
		scene.add(light2);
		scene.add(light3);
		scene.add(light4);
		camera.lookAt(0, 0, 0);
		scene.background = new THREE.Color(0xb8c2cc);

		// Load Stl
		const loader = new STLLoader();
		const geometry = await new Promise((resolve) => {
			// loader.load("./CONFIDENTIAL_rotor mod.stl", (geometry) => {
			loader.load("./box.stl", (geometry) => {
				geometry.scale(0.1, 0.1, 0.1); // Adjust the scale as needed
				const material = new THREE.MeshPhongMaterial({
					color: "#272525",
					specular: 0xffffff,
					shininess: 100,
					emissive: 0x101010,
				});
				const mesh = new THREE.Mesh(geometry, material);
				mesh.geometry.center();
				scene.add(mesh);
				resolve(geometry);
			});
		});

		const boundingBox = geometry.boundingBox;
		const maxDimension = Math.max(
			boundingBox.max.x - boundingBox.min.x,
			boundingBox.max.y - boundingBox.min.y,
			boundingBox.max.z - boundingBox.min.z
		);
		const distance = maxDimension * 0.7;
		camera.position.set(distance, distance, distance);
		// light.position.set(distance, distance, distance);

		// Create a WebGLRenderer without specifying a canvas
		const renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setSize(800, 600);

		// Render the scene onto the renderer
		renderer.render(scene, camera);

		// Get the screenshot as a data URL directly from the renderer
		const dataUrl = renderer.domElement.toDataURL("image/png");

		// Update the state with the screenshot data URL
		setScreenshotDataUrl(dataUrl);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
			<button
				onClick={takeScreenshot}
				style={{
					padding: "10px",
					backgroundColor: "black",
					color: "white",
					margin: "20px",
				}}
			>
				Take Screenshot
			</button>
			{screenshotDataUrl && (
				<img
					src={screenshotDataUrl}
					alt="Screenshot"
					style={{ maxWidth: "100%", marginTop: "10px" }}
				/>
			)}

			<canvas
				style={{
					display: "none",
				}}
				// ref={hiddenCanvasRef}
				width={800}
				height={600}
			/>
		</div>
	);
};

export default ScreenshotButton;
