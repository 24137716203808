/*global Autodesk, THREE*/
const DrawBoundsToolName = "draw-bounds-tool";
const DrawBoundsOverlayName = "draw-bounds-overlay";

class DrawBoundsTool extends Autodesk.Viewing.ToolInterface {
	constructor(viewer) {
		super();
		this.viewer = viewer;
		this.names = [DrawBoundsToolName];
		this.active = false;
		this.snapper = null;
		this.points = [];
		this.mesh = null;
		// Hack: delete functions defined on the *instance* of a ToolInterface (we want the tool controller to call our class methods instead)
		delete this.register;
		delete this.deregister;
		delete this.activate;
		delete this.deactivate;
		delete this.getPriority;
		delete this.handleMouseMove;
		delete this.handleSingleClick;
		delete this.handleKeyUp;
	}

	register() {
		this.snapper = new Autodesk.Viewing.MeasureCommon.Snapper(this.viewer);
		//this.snapper.activate();
		this.viewer.toolController.registerTool(this.snapper);
		this.viewer.toolController.activateTool(this.snapper.getName());

		console.log("DrawBoundsTool registered.");
	}

	deregister() {
		this.viewer.toolController.deactivateTool(this.snapper.getName());
		this.viewer.toolController.deregisterTool(this.snapper);
		this.snapper = null;
		console.log("DrawBoundsTool unregistered.");
	}

	activate(name, viewer) {
		if (!this.active) {
			this.viewer.overlays.addScene(DrawBoundsOverlayName);
			console.log("DrawBoundsTool activated.");
			this.active = true;
		}
	}

	deactivate(name) {
		if (this.active) {
			this.viewer.overlays.removeScene(DrawBoundsOverlayName);
			console.log("DrawBoundsTool deactivated.");
			this.active = false;
		}
	}

	getPriority() {
		return 42; // Feel free to use any number higher than 0 (which is the priority of all the default viewer tools)
	}

	async handleSingleClick(event, button) {
		if (!this.active) {
			return false;
		}

		if (button === 0 && this.snapper.isSnapped()) {
			const snapper = new Autodesk.Viewing.MeasureCommon.Snapper(this.viewer);

			const hitTestResult = this.viewer.impl.snappingHitTest(
				event.x,
				event.y,
				false
			);

			console.log("snapped");

			var mCurrentSnap = await this.snapping3D(hitTestResult, snapper);
			console.log(mCurrentSnap);

			this.createOverlay();
			this.AddGeomFaceToOverlay(mCurrentSnap);

			console.log(mCurrentSnap);

			return true; // Stop the event from going to other tools in the stack
		}
		return false;
	}

	async snapping3D(result, snapper) {
		var top = await this.viewer.model.fetchTopology();

		console.log(this.viewer.model.hasTopology());
		var mCurrentSnap = false;
		var snapNode = result.dbId;

		var face = result.face;
		var fragIds;

		if (result.fragId.length === undefined) {
			fragIds = [result.fragId];
		} else {
			fragIds = result.fragId;
		}

		// This is for Fusion model with topology data
		if (this.viewer.model.hasTopology()) {
			// Because edge topology data may be in other fragments with same dbId, need to iterate all of them.
			if (snapNode) {
				fragIds = [];

				this.viewer.model.getData().instanceTree.enumNodeFragments(
					snapNode,
					function (fragId) {
						fragIds.push(fragId);
					},

					true
				);
			}

			for (var fi = 0; fi < fragIds.length; ++fi) {
				var fragId = fragIds[fi];
				var mesh = this.viewer.impl.getRenderProxy(this.viewer.model, fragId);
				var geometry = mesh.geometry;

				var topoIndex = this.viewer.model.getTopoIndex(fragId);
				var topology = this.viewer.model.getTopology(topoIndex);
				var facesTopology = topology.faces;
				var snapGeom = snapper.faceSnappingWithTopology(
					face,
					geometry,
					facesTopology,
					mesh
				);
				// _self.mCurrentSnap = _self.snapper.faceSnappingWithTopology(face, geometry, facesTopology, mesh);

				if (snapGeom) {
					if (!mCurrentSnap) {
						mCurrentSnap = snapGeom;
					} else {
						mCurrentSnap.merge(snapGeom, snapGeom.matrix);
					}
				}
			}
		} else {
			for (let fi = 0; fi < fragIds.length; ++fi) {
				let fragId = fragIds[fi];
				let mesh = this.viewer.impl.getRenderProxy(this.viewer.model, fragId);
				let geometry = mesh.geometry;

				mCurrentSnap = snapper.faceSnapping(face, geometry);

				if (mCurrentSnap) {
					mCurrentSnap.applyMatrix(mesh.matrixWorld);
				}
				break;
			}
		}
		return mCurrentSnap;
	}

	AddGeomFaceToOverlay(geomFace) {
		this.createOverlay();

		if (true) {
			var _material = new THREE.MeshPhongMaterial({
				color: "#FF0000",
				ambient: "0xEEA630",
				opacity: 1.0,
				transparent: false,
				depthTest: false,
				depthWrite: false,
				// side: THREE.DoubleSide,
			});
			geomFace.computeVertexNormals();
			console.log(geomFace.computeBoundingBox());
			var snapperPlane = new THREE.Mesh(geomFace, _material, true);
			this.viewer.impl.addOverlay("scene", snapperPlane);

			// _self.mOvelayFaces[geomFace.faceId] = snapperPlane;
		}
	}

	createOverlay() {
		if (!this.viewer.impl.overlayScenes["scene"])
			this.viewer.impl.createOverlayScene("scene");
	}

	handleKeyUp(event, keyCode) {
		if (this.active) {
			if (keyCode === 27) {
				// Finalize the extrude mesh and initialie a new one
				this.points = [];
				this.mesh = null;
				return true;
			}
		}
		return false;
	}
}

class DrawBoundsToolExtension extends Autodesk.Viewing.Extension {
	constructor(viewer, options) {
		super(viewer, options);
		this.tool = new DrawBoundsTool(viewer);
		// this.button = null;
	}

	logger() {
		console.log("Logging from Selection");
	}

	async load() {
		//await this.viewer.loadExtension("Autodesk.Viewing.MeasureCommon");
		this.viewer.toolController.registerTool(this.tool);
		console.log("DrawBoundsToolExtension has been loaded.");
		return true;
	}

	async unload() {
		this.viewer.toolController.deregisterTool(this.tool);
		console.log("DrawBoundsToolExtension has been unloaded.");
		return true;
	}

	// Adds Toobar button in the viewer
	// onToolbarCreated(toolbar) {
	// 	const controller = this.viewer.toolController;
	// 	this.button = new Autodesk.Viewing.UI.Button("draw-bounds-tool-button");

	// 	this.button.onClick = (ev) => {
	// 		if (controller.isToolActivated(DrawBoundsToolName)) {
	// 			controller.deactivateTool(DrawBoundsToolName);
	// 			this.button.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
	// 		} else {
	// 			controller.activateTool(DrawBoundsToolName);
	// 			this.button.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
	// 		}
	// 	};

	// 	this.button.setToolTip("Draw Bounds Tool");
	// 	this.group = new Autodesk.Viewing.UI.ControlGroup("draw-tool-group");
	// 	this.group.addControl(this.button);
	// 	toolbar.addControl(this.group);
	// }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
	"DrawBoundsToolExtension",
	DrawBoundsToolExtension
);
