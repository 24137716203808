import { useEffect, useState } from "react";
import styles from "./partDetailsPage.module.css";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import {
	clearPartAnalysis,
	fetchPartDetails,
} from "redux/Slice/PartAnalysisSlice";

// Library imports
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import {
	MdArrowBack,
	MdNotInterested,
	MdOutline3DRotation,
} from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Skeleton from "react-loading-skeleton";

// Pages Import
import {
	CostTab,
	GenerativeTab,
	LeadTimeTab,
	MaterialAnalysisTab,
	PartOverview,
	PartRenderer,
	SuitabilityAnalysisTab,
} from "./";
import { getObjectUrlFromS3 } from "AWS/awsService";

export const PartDetailsPage = () => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [isImageError, setIsImageError] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const location = useLocation();
	console.log(location);

	const { imagesArray } = useSelector((state) => state.projectAnalysisSlice);

	const handleImageLoad = () => {
		setIsImageLoaded(true);
	};

	const handleImageError = () => {
		setIsImageError(true);
	};

	const { partID, projectID } = useParams();
	const trimmedPartId = partID.split("parts-")[1];
	const [imageUrl, setImageUrl] = useState(null);
	const [render, setRender] = useState(null);
	const [partModal, setShowPartModal] = useState(false);
	const dispatch = useDispatch();

	const {
		partName,
		materialId,
		overviewTab,
		materialAnalysisTab,
		suitabilityAnalysisTab,
		costAnalysisTab,
		leadTimeTab,
		generativeTab,
		partAnalysisLoading,
	} = useSelector((state) => state.partAnalysisSlice?.partAnalysisData);

	console.log("Overview Data", overviewTab);

	const userID = useSelector((state) => state.authSlice?.user?.id);

	const tabsList = [
		"Part Overview",
		"Material Analysis",
		"Suitability Analysis",
		"Cost Analysis",
		"Lead Time Analysis",
		"Generative Design",
	];

	const RenderTabComponent = () => {
		switch (activeTab) {
			case 0:
				return (
					<PartOverview
						data={overviewTab}
						quantities={leadTimeTab}
					/>

					// OverviewTab: 	{
					// 		name: 'CorrectWaveguide.stp',
					// 		result: 'non-printable',
					// 		costEstimation: '4052.41',
					// 		leadTime: 1,
					// 		recommendedPrinter: 'iFusion150',
					// 		recommendedMaterial: 'Nickel Alloy IN718'
					//   }
				);

			case 1:
				return (
					<MaterialAnalysisTab
						data={materialAnalysisTab}
						materialData={{
							"Intech Qualified": overviewTab.recommendedAmMaterial,
							"Globally Available": overviewTab.recommendedGlobalMaterial,
						}}
					/>
				);

			case 2:
				return <SuitabilityAnalysisTab data={suitabilityAnalysisTab} />;

			case 3:
				return (
					<CostTab
						recievedData={costAnalysisTab}
						quantities={leadTimeTab}
					/>
				);

			case 4:
				return <LeadTimeTab data={leadTimeTab} />;

			case 5:
				return (
					<GenerativeTab
						imageUrl={imageUrl}
						data={generativeTab}
						file={render}
					/>
				);

			default:
				return;
		}
	};

	const getUrlOfPart = async (path) => {
		return await getObjectUrlFromS3(path);
	};

	const handlePartModalToggle = () => {
		setShowPartModal(!partModal);
	};

//Fetch Part Details Dispatch
useEffect(() => {
  (async () => {
    try {
      // Fetch part details
      await dispatch(fetchPartDetails(trimmedPartId));

      // Check the condition and set active tab
      if (location.state.tab === 3 && activeTab !== 3) {
        setActiveTab(3);
      }

    } catch (error) {
      // Handle errors if any
      console.error('Error fetching part details:', error);
    }

    // Cleanup or additional actions if needed
    // return () => dispatch(clearPartAnalysis());

  })(); // This invokes the function immediately
}, [trimmedPartId]);


	useEffect(() => {
		const path =
			`${userID}/project-${projectID}/material-${materialId}/` + partName;
		// `userId/project-9000e2be-5910-4733-bb8a-5c86c205be2c/material-a5f138b0-0466-44f9-8bf4-3c45e069b946/CONFIDENTIAL_rotor mod.stl`;

		const fetchPartLink = async () => {
			//Replace to fetch stl of stp files
			const partLink = await getUrlOfPart(path.replace(".stp", ".stl"));
			setRender(partLink);
			console.log("render Link -----", partLink);
		};

		fetchPartLink();
		return () => setRender(null);

		// Included userID because on reload it remains undefined
	}, [materialId, partName, userID, userID]);

	const regex = /\.(stl|stp|step)$/g;
	useEffect(() => {
		// Fetch the image URL when the component mounts
		async function fetchImage() {
			const imageKey = `${userID}/project-${projectID}/material-${materialId}/snapshot/${partName?.replace(
				regex,
				""
			)}.png`;
			console.log(imageKey);
			// const imageKey = `userId/project-9000e2be-5910-4733-bb8a-5c86c205be2c/material-a5f138b0-0466-44f9-8bf4-3c45e069b946/snapshot/CONFIDENTIAL_rotor mod.stl.png`;
			const url = await getObjectUrlFromS3(imageKey);
			setImageUrl(url);
		}
		fetchImage();
		return () => setImageUrl(null);

		// Included userID because on reload it remains undefined
	}, [materialId, partName, userID, userID]);

	// TO navigate to Cost tab when navigated from Zone Map component
	// useEffect(() => {
	// 	if (partName?.length > 0 && location.state.tab === 3 && activeTab !== 3) {
	// 		setActiveTab(3);
	// 	}
	// }, []);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.partHeader}>
				<div style={{ display: "flex" }}>
					<Link
						to={-1}
						className={styles.backHeader}
					>
						<MdArrowBack
							size={20}
							color="gray"
						/>

						<div>
							<span>Back to Project</span>
						</div>
					</Link>

					{/* <h2>
						{overviewTab?.name ?? (
							<Skeleton
								width={"20rem"}
								baseColor="#b8c2cc"
								highlightColor="#d3dfeb"
							/>
						)}
					</h2> */}
				</div>

				<div className={styles.tabListMainContainer}>
					<div className={styles.tabList}>
						{tabsList.map((tab, index) => (
							<button
								key={index}
								onClick={() => {
									if (partName?.split(".")[1] === "stl" && index === 5) {
										return;
									}
									setActiveTab(index);
								}}
								className={`${index === activeTab ? styles.active : ""} ${
									//Toggle Active Tab

									partName?.split(".")[1] === "stl" && index === 5
										? styles.disable
										: ""
								} ${index === 5 && styles.betaContainerClass}`} //Beta tag class, remove after use
								style={
									partName?.split(".")[1] === "stl" && index === 5 //Style when file is .stl and in Generative tab
										? btnStyle
										: index === 5
										? { paddingRight: "2.5rem" } //Beta tag spacing, remove after use
										: {}
								}
								data-tooltip-id={index}
								data-tooltip-content="Generative Design is Available only for Step files"
								data-tooltip-delay-show="150"
							>
								{partName?.split(".")[1] === "stl" && index === 5 && (
									<MdNotInterested color="grey" />
								)}

								{tab}

								{/* Beta tag  */}
								{index === 5 && <span>Beta</span>}

								<ReactTooltip
									id={partName?.split(".")[1] === "stl" && index === 5 && 5} //Conditionally applying id to show only on Generative tab
									place="bottom"
									style={generativeToolTip}
								/>
							</button>
						))}
					</div>
				</div>
			</div>

			<div className={styles.main}>
				{/* RENDERER COMPONENT */}

				<div className={styles.rendererComponentContainer}>
					<div className={styles.rendererComponent}>
						<div
							style={{
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{!isImageLoaded && !isImageError && (
								<Skeleton
									height={"80.5vh"}
									width={"30.5vw"}
									baseColor="#b8c2cc"
									highlightColor="#d3dfeb"
								/>
							)}

							<img
								src={
									imagesArray?.find((image) => image?.partID === trimmedPartId)
										?.image ?? imageUrl
								}
								alt="Snapshot"
								onLoad={handleImageLoad}
								onError={handleImageError}
								style={{
									display: isImageLoaded || isImageError ? "block" : "none",
								}}
							/>
						</div>
					</div>

					<div
						className={styles.renderBtn}
						onClick={handlePartModalToggle}
					>
						<MdOutline3DRotation
							className={styles.renderIcon}
							size={30}
							color="white"
						/>
					</div>

					<div>
						{partModal && (
							<PartRenderer
								file={render}
								partName={overviewTab?.name.replace(regex, "")}
								onClose={handlePartModalToggle}
							></PartRenderer>
						)}
					</div>
				</div>

				{/* RENDER TABS */}
				<div
					style={{
						display: "grid",
						boxShadow: " 0px 2px 5px rgba(0, 0, 0, 0.1)",
						backgroundColor: "#f7f7f7",
						height: "100%",
					}}
				>
					<RenderTabComponent />
				</div>
			</div>
		</div>
	);
};

// This Style applies when Generative tab is disabled
const btnStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "0.5rem",
};

const generativeToolTip = {
	width: "25rem",
	zIndex: "4",
};
