export const trimPartName = (partName, nameLimit = 10) => {
	if (partName.length <= nameLimit) {
		return partName;
	}

	const ellipsis = "...";
	const nameWithoutExtension = partName;
	let extension = "";
	if (partName.split(".").length > 1) {
		extension = partName
			.split(".")
			.slice(partName.split(".").length - 1)
			.join(".");
	}
	const trimmedName = nameWithoutExtension.substring(0, nameLimit - 4);

	return trimmedName + ellipsis + extension;
};

// Utility function to generate a random color in hexadecimal format
export const getRandomColor = () => {
	const letters = "0123456789ABCDEF";

	let color = "#";

	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}

	return color;
};

//Currency Converter
export const currencyConversion = (value, currencyType) => {
	const ExchangeRate = {
		USD: 0.012, // 1 INR = 0.012 USD
		JPY: 1.76, // 1 INR = 1.76 YEN
		EUR: 0.011, // 1 INR = 0.011 EUR
		INR: 1,
	};

	const convertedValue = (
		parseFloat(value) * ExchangeRate[currencyType]
	).toFixed(2);

	const newValue = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: currencyType,
	}).format(convertedValue);

	return newValue;
};
