import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "api/axios";
const partAnalysisSlice = createSlice({
	name: "partAnalysis",

	initialState: {
		partAnalysisData: {},

		latticeInfillData: {
			offsetValue: null,
			meshType: null,
		},

		topologyData: {},
	},

	reducers: {
		clearPartAnalysis: (state) => {
			state.partAnalysisData = {};
		},

		setLatticeData: (state, action) => {
			const { type, value } = action.payload;

			switch (type) {
				case "offset":
					state.latticeInfillData.offsetValue = value;
					break;

				case "mesh":
					state.latticeInfillData.meshType = value;
					break;

				default:
			}
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchPartDetails.pending, (state) => {
			state.partAnalysisLoading = true;
		});

		builder.addCase(fetchPartDetails.fulfilled, (state, action) => {
			state.partAnalysisLoading = false;
			state.partAnalysisData = action.payload;
		});

		builder.addCase(fetchPartDetails.rejected);
	},
});

export const { clearPartAnalysis, setLatticeData } = partAnalysisSlice.actions;

export const fetchPartDetails = createAsyncThunk(
	"partAnalysis/get",
	async (partId) => {
		const projectAnalysisJSON = await axios.get(
			`/api/v1/analysis/parts/${partId}`
		);

		console.log(projectAnalysisJSON);
		return projectAnalysisJSON.data.partResponse;
	}
);

export default partAnalysisSlice.reducer;
