import ScreenshotButton from "ScreenshotButton";
import React from "react";

const Screenshot = () => {
	return (
		<div>
			<ScreenshotButton />
		</div>
	);
};

export default Screenshot;
