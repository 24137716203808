import axios from "axios";

export const getApsAccessToken = (callback) => {
  // ref: https://aps.autodesk.com/en/docs/oauth/v2/tutorials/get-2-legged-token
  console.log("In GEt TOKEN");
  const apiUrl =
    "https://roff70te28.execute-api.us-west-2.amazonaws.com/development/access-token";
  // "https://77fvkau4nd.execute-api.ap-south-1.amazonaws.com/test/auth";

  axios
    .get(apiUrl)

    .then((response) => {
      if (response.status === 200) {
        var token = JSON.parse(response.data.body);
        var accessToken = token.access_token;

        console.log(accessToken);

        callback(accessToken, 9999);
      } else {
        console.error("Failed to retrieve the access token.");

        callback("Failed to retrieve the access token.", null);
      }
    })
    .catch((error) => {
      console.error("Error:", error);

      callback(error, null);
    });
};

export async function getAccessTokenFromLambda() {
  const apiUrl =
    "https://roff70te28.execute-api.us-west-2.amazonaws.com/development/access-token";

  try {
    const response = await axios.get(apiUrl);

    if (response.status === 200) {
      var token = JSON.parse(response.data.body);
      var accessToken = token.access_token;

      return accessToken;
    } else {
      console.error("Failed to retrieve the access token.");

      return null;
    }
  } catch (error) {
    console.error("Error:", error);

    return null;
  }
}
