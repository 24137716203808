// Switch for the selction extension toggle in Topology viewer

import React, { useState } from "react";
import styles from "./switch.module.css";

export const Switch = ({ labelOn, labelOff, onClick }) => {
  const [isChecked, setChecked] = useState(false);

  const toggleSwitch = () => {
    setChecked(!isChecked);

    // Run onClick fn passed through props
    onClick();
  };

  return (
    <div className={styles.SwitchContainer} onClick={toggleSwitch}>
      <div
        className={`${styles.SwitchRoot} ${isChecked ? styles.checked : ""}`}
      >
        <div className={styles.SwitchThumb} id="switch"></div>
      </div>

      <label htmlFor="switch" className={`${isChecked ? styles.checked : ""}`}>
        {isChecked ? labelOn : labelOff}
      </label>
    </div>
  );
};
