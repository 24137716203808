// import "./renderer.css";
import { Canvas, useLoader, useThree } from "react-three-fiber";
import { Suspense, useEffect, useRef, useState } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdClose,
  MdFullscreenExit,
  MdOutlineFullscreen,
} from "react-icons/md";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";
import "./partrenderer.css";
import Loader from "./ModelLodader";
//AXIS INDICATOR

// CAMERA CONTROLS
const CameraController = () => {
  const { camera, gl } = useThree();
  useEffect(() => {
    const controls = new OrbitControls(camera, gl.domElement);
    controls.minDistance = 20;
    controls.maxDistance = 900;
    // camera.lookAt(0, 0, 0);
    // camera.up.set(0, 0, 1);
    return () => {
      controls.dispose();
    };
  }, [camera, gl]);
  return null;
};

//USER RENDER GEOMETRY
const Geom = (props) => {
  const meshref = useRef();
  const [bb, setbb] = useState(null);
  const stl = useLoader(STLLoader, props.name, (geometry) => {});
  const boundingBoxRef = useRef(new THREE.Box3());
  const newbbox = CalculateBoundingBox(stl);
  console.log("new bounding box - ", newbbox);

  useEffect(() => {
    // Update bounding box when the orientation changes
    boundingBoxRef.current.setFromObject(meshref.current);
    // setbb(meshref.current.geometry.boundingBox);
    setbb(boundingBoxRef.current);
  }, [props.stl]);

  stl.center();

  return (
    <mesh castShadow ref={meshref}>
      <primitive object={stl} castShadow />
      {/* <meshStandardMaterial color="#5F635F" /> */}
      <meshStandardMaterial
        color="#4e4e4e"
        // emissive={0x101010}
        specular={0xffffff}
        roughness={0.2}
        wireframe={props.wireframe}
      />
    </mesh>
  );
};

//LIGHT GEOMETRY
const Bulb = (props) => {
  return (
    <mesh {...props}>
      {/* <pointLight castShadow /> */}
      <pointLight intensity={1} color="white" castShadow />
      {/* takes radius in args */}
      {/* <sphereGeometry args={[10]} /> */}
      <meshPhongMaterial emissive="yellow" />
    </mesh>
  );
};

// FUNCTIONS
const CalculateBoundingBox = (stlfile) => {
  const val = stlfile.boundingBox;
  return val;
};

// RENDERER MAIN FUNCTION TO ENTER INTO THE STL RENDERER -------------------------------------------------
export const PartRenderer = (props) => {
  console.log(props.partName);
  const [pos, setPos] = useState([0, 0, 0]);
  const scale = 0.001;
  const [fullScreen, setFullScreen] = useState(false);
  const [wireframe, setWireframe] = useState(false);

  return (
    <div className="modal">
      <div
        className="modal-content"
        style={{ position: `${fullScreen ? "static" : "relative"}` }}
      >
        <div className="canvas-main-container">
          <div className="canvas-icons-conainer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <span style={{ marginRight: "1rem" }}>Wireframes</span>
              {wireframe ? (
                <MdCheckBox
                  color="white"
                  size={30}
                  onClick={() => setWireframe((prev) => !prev)}
                />
              ) : (
                <MdCheckBoxOutlineBlank
                  color="white"
                  size={30}
                  onClick={() => setWireframe((prev) => !prev)}
                />
              )}
            </div>
            {!fullScreen ? (
              <MdOutlineFullscreen
                className="canvas-fullscreen-icon"
                size={30}
                color="white"
                onClick={() => setFullScreen(true)}
              />
            ) : (
              <MdFullscreenExit
                className="canvas-fullscreen-icon"
                size={30}
                color="white"
                onClick={() => setFullScreen(false)}
              />
            )}
            <MdClose
              className="canvas-close-icon"
              onClick={props.onClose}
              size={30}
              color="white"
            />
          </div>
          <p
            style={{
              zIndex: 1,
              position: "absolute",
              left: "0.6rem",
              top: "0.6rem",
              color: "black",
              background: "white",
              padding: "0.1rem 0.6rem",
              fontWeight: "500",
              fontSize: "1.4rem",
              opacity: "0.8",
            }}
          >
            {"Part Name - "}
            <span style={{ fontWeight: "400", opacity: "1", color: "gray" }}>
              {"  " + props.partName}
            </span>
          </p>

          <Canvas
            shadows
            style={{
              // background: "linear-gradient(to right, #232526, #414345)",
              background: "var(--card-grey)",
            }}
            camera={{
              position: [100, 100, 100],
            }}
            pixelRatio={window.devicePixelRatio}
          >
            <Suspense fallback={<Loader />}>
              {/* <Axes /> */}
              <Geom name={props.file} wireframe={wireframe} />
            </Suspense>

            <Bulb position={[200, 200, 200]} />
            <Bulb position={[-200, 200, -200]} />
            <Bulb position={[200, 200, -200]} />
            <Bulb position={[-200, 200, 200]} />
            <Bulb position={[-0, -300, 0]} />
            <ambientLight intensity={10} color={"#3e3e3e"} />
            <CameraController />
          </Canvas>
          <div className="canvas-bottom">
            {/* <p
							style={{
								color: "black",
								background: "white",
								padding: "0.1rem 0.6rem",
							}}
						>
							{"Part Name - "}
							<span style={{ fontWeight: "500", opacity: "0.8" }}>
								{"  " + props.partName}
							</span>
						</p> */}
            <p
              style={{
                position: "absolute",
                bottom: "0",
                right: "0rem",
                fontWeight: "500",
                opacity: "0.8",
                fontSize: "1.2rem",
                color: "black",
                background: "white",
                padding: "0.4rem",
              }}
            >
              Powered by Intech Additive Solutions Pvt Ltd
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
