import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "Utils/constants";
import axios from "api/axios";

const projectSlice = createSlice({
  name: "projects",
  initialState: {
    projectsList: [],
    selectedJobToDelete: null,
    projectsListStatus: null,
    projectListError: null,
  },

  reducers: {
    setSelectedJobToDelete: (state, action) => {
      state.selectedJobToDelete = action.payload;
    },

    addProject: (state, action) => {
      state.projectsList.push(action.payload);
    },
  },

  extraReducers: (builder) => {
    //Get Projects Actions
    builder.addCase(fetchProjects.pending, (state) => {
      state.projectsListStatus = RequestStatus.LOADING;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projectsListStatus = RequestStatus.FULFILLED;
      state.projectsList = action.payload;
    });
    builder.addCase(fetchProjects.rejected, (state, action) => {
      state.projectsListStatus = RequestStatus.ERROR;
      state.projectListError = action.error.message;
    });

    //Bookmark Single Project Actions
    builder.addCase(bookmarkProject.pending);
    builder.addCase(bookmarkProject.fulfilled, (state, action) => {
      state.projectsList = action.payload;
    });
    builder.addCase(bookmarkProject.rejected);

    //Delete Project Actions
    builder.addCase(deleteProject.pending);
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.projectsList = action.payload;
    });
    builder.addCase(deleteProject.rejected);
  },
});

export const { setSelectedJobToDelete } = projectSlice.actions;

export const fetchProjects = createAsyncThunk("projects/get", async () => {
  try {
    const projectsListJSON = await axios.get(
      `/api/v1/projects`,
      // `http://localhost:4000/projectsList`
    );
    console.log(projectsListJSON);
    return projectsListJSON.data.projectsList;
    // return projectsListJSON;
  } catch (err) {
    return err.projectsList;
  }
});

export const bookmarkProject = createAsyncThunk(
  "project/bookmark",
  async ({ id, updatedProject }, { getState }) => {
    const { projectSlice } = getState();
    try {
      const response = await axios.put(`/api/v1/projects/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedProject),
      });
      const updatedProjectsList = projectSlice.projectsList.map((project) =>
        project.id === response.data.id ? response.data : project,
      );
      return updatedProjectsList;
    } catch (error) {
      console.error("Error updating project:", error);
      return null;
    }
  },
);

export const deleteProject = createAsyncThunk(
  "project/delete",
  async (projectId, { getState }) => {
    const { projectSlice } = getState();
    try {
      const response = await axios.delete(`/api/v1/projects/${projectId}`, {
        method: "DELETE",
      });
      console.log(response.data.id);

      //TODO : Add DeleteProjectFromS3 fn from awsService

      const updatedProjectsList = projectSlice.projectsList.filter(
        (project) => project.id !== response.data.id,
      );

      return updatedProjectsList;
    } catch (error) {
      console.error("Error deleting project:", error);
      return null;
    }
  },
);

export default projectSlice.reducer;
