import { Link } from "react-router-dom";
import styles from "./error404.module.css";

export const Error404 = () => {
	return (
		<div className={styles.errorPage}>
			<h1 className={styles.error404}>Error 404</h1>
			<h2 className={styles.errorHeading}> Page Not Found</h2>
			<p className={styles.errorText}>
				Return to{" "}
				<Link
					to="/"
					className={styles.link}
					replace
				>
					Home
				</Link>
				!
			</p>
		</div>
	);
};
