import styles from "./zoneMap.module.css"; // Create this CSS file to style the component
import { useEffect } from "react";
import { Tooltip } from "react-tooltip";

export const ZoneMap = ({
	zoneSpecificData,
	setSelectedZone,
	selectedZone,
}) => {
	const xLabels = ["0", "1"];
	const yLabels = ["100", "60", "0"];

	const getColorFromScore = (score) => {
		const colorPalette = [
			// "#2ecc71",
			// "#2ecc71",
			"#43d17f",
			"#29b866",
			// "#58d68d",
			"#abebc6",
			"#6ddb9c",
			// "#82e0aa",
			// "#97e6b8",
		];

		return colorPalette[score - 1];
	};

	// Zone Cells data
	const zoneScores = [1, 2, 3, 4];
	const zoneData = zoneScores.map(
		(score) => [...zoneSpecificData(score)].length
	);

	const handleZoneClick = (e, index) => {
		if (selectedZone !== index) {
			setSelectedZone(index);
		} else setSelectedZone(-1);
	};

	//On Click Changes, order matters here
	useEffect(() => {
		const zones = document.querySelectorAll(`.${styles.zone}`);

		zones.forEach((zone) => {
			//Animation class is removed on load
			zone.classList.remove(styles.zoneAppear);

			if (selectedZone === -1) {
				zone.classList.remove(styles.zoneActive);
				zone.classList.remove(styles.zoneInactive);
				return;
			}

			if (+zone.id === selectedZone) {
				zone.classList.add(styles.zoneActive);
				zone.classList.remove(styles.zoneInactive);
			} else {
				zone.classList.add(styles.zoneInactive);
				zone.classList.remove(styles.zoneActive);
			}
		});
	}, [selectedZone]);

	const TooltipContent = (zone) => {
		console.log(zone);
		switch (zone) {
			case 1:
				return "High complexity and low quantity";

			case 2:
				return "High complexity and Moderate quantity";

			case 3:
				return "High complexity and high quantity";

			case 4:
				return "Moderate complexity and low quantity";

			case 5:
				return "Moderate complexity and Moderate quantity";

			case 6:
				return "Moderate complexity and high quantity";

			case 7:
				return "Low complexity and low volume";

			case 8:
				return "Low complexity and moderate volume";

			case 9:
				return "Low complexity and high volume";

			default:
				return null;
		}
	};

	//On Load Animation
	useEffect(() => {
		const zones = document.querySelectorAll(`.${styles.zone}`);
		zones.forEach((zone, index) => {
			//Adds animation on first load
			zone.classList.add(styles.zoneAppear);
			zone.style.animationDelay = `${index * 50}ms`;
		});
	}, []);

	return (
		<div className={styles["zone-map-container"]}>
			{/* Y-axis labels */}
			<div className={styles["y-axis-labels"]}>
				{yLabels.map((label, index) => (
					<div
						key={index}
						className={styles["axis-label"]}
					>
						{label}
					</div>
				))}

				<div className={styles.yAxis}>
					<span>Complexity</span>
				</div>
			</div>

			{/* Main zone map */}
			<div className={styles.zoneContainer}>
				<div className={styles["zone-map"]}>
					{zoneData.map((score, index) => (
						<div
							key={index}
							id={index}
							className={styles.zone}
							style={{
								backgroundColor: getColorFromScore(index + 1),
							}}
							onClick={(e) => handleZoneClick(e, index)}
							data-tooltip-id={`zone-tooltip` + index}
							data-tooltip-content={TooltipContent(index + 1)}
							data-tooltip-delay-show="150"
							data-tooltip-position-strategy="fixed"
						>
							{score}

							<Tooltip
								id={`zone-tooltip` + index}
								style={{
									width: "15rem",
									fontSize: "1.2rem",
								}}
							/>
						</div>
					))}
				</div>

				{/* X-axis labels */}
				<div className={styles["x-axis-labels"]}>
					{xLabels.map((label, index) => (
						<div
							key={index}
							className={styles["axis-label"]}
						>
							{label}
						</div>
					))}

					<div className={styles.xAxis}>
						<span>Economical Factor</span>
					</div>
				</div>
			</div>
		</div>
	);
};
