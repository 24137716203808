import "./App.css";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

//layouts
import { RootLayout } from "./layouts/RootLayout";

//pages
import {
  Error404,
  Home,
  Settings,
  ProjectAnalysis,
  PartDetailsPage,
} from "./pages";

//Components
import { Error } from "components";
import Screenshot from "Screenshot";
import Converter from "Converter";
import { HomeError } from "pages/HomePage/HomeError/HomeError";

const IndexComponent = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />} errorElement={<Error404 />}>
        <Route index element={<Home />} errorElement={<HomeError />} />
        <Route path="converter" element={<Converter />} />
        <Route path="converter" element={<Converter />} />

        <Route path=":projectID" element={<ProjectAnalysis />} />

        <Route path=":projectID/:partID" element={<PartDetailsPage />} />

        <Route path="/render" element={<Screenshot />} />
        <Route path="/converter" element={<Converter />} />
        {/* <Route
					path="/compress"
					element={<FileCompressor />}
				/> */}
        <Route path="settings" element={<Settings />} />

        <Route path="*" element={<Error404 />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

function App() {
  return (
    <>
      <ToastContainer />

      <IndexComponent />
    </>
  );
}

export default App;
