// import "./renderer.css";
import { Canvas, useLoader, useThree } from "react-three-fiber";
import { Suspense, useEffect, useRef, useState } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { MdClose, MdFullscreenExit, MdOutlineFullscreen } from "react-icons/md";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";
import { STLExporter } from "three/addons/exporters/STLExporter";
// import "./partrenderer.css";
import { SimplifyModifier } from "three/examples/jsm/modifiers/SimplifyModifier";
// import { Exporter } from "./Exporter";
import Loader from "../src/pages/PartDetailsPage/PartRenderer/ModelLodader";
//AXIS INDICATOR

// CAMERA CONTROLS
const CameraController = () => {
	const { camera, gl } = useThree();
	useEffect(() => {
		const controls = new OrbitControls(camera, gl.domElement);
		// controls.minDistance = 80;
		// controls.maxDistance = 900;
		// camera.lookAt(0, 0, 0);
		// camera.up.set(0, 0, 1);
		return () => {
			controls.dispose();
		};
	}, [camera, gl]);
	return null;
};

// const Geom = (props) => {
// 	const meshRef = useRef();
// 	const stl = useLoader(STLLoader, "/cureTool.stl"); // Load STL model

// 	// Create a SimplifyModifier instance
// 	const modifier = new SimplifyModifier();

// 	// Clone the original geometry
// 	const originalGeometry = stl.clone();

// 	// Log the count of triangles before simplification
// 	console.log(
// 		"Original Triangle Count:",
// 		originalGeometry.attributes.position.count / 3
// 	);

// 	// Modify the geometry to reduce the number of triangles
// 	const targetTriangleCount = Math.floor(
// 		originalGeometry.attributes.position.count * 0.6
// 	);
// 	// const simplifiedGeometry = modifier.modify(
// 	// 	originalGeometry,
// 	// 	targetTriangleCount
// 	// );
// 	const simplifiedGeometry = modifier.modify(originalGeometry, 9000);

// 	// Log the count of triangles after simplification
// 	console.log(
// 		"Simplified Triangle Count:",
// 		simplifiedGeometry.attributes.position.count / 3
// 	);
// 	// download file
// 	// const exporter = new STLExporter();
// 	// // const simplifiedGeometry = /* get the simplified geometry */;
// 	// const data = exporter.parse(simplifiedGeometry, { binary: true });
// 	// const blob = new Blob([data], { type: "application/octet-stream" });
// 	// const link = document.createElement("a");
// 	// link.href = URL.createObjectURL(blob);
// 	// link.download = "simplified_model.stl";
// 	// link.click();
// 	// URL.revokeObjectURL(link.href);

// 	useEffect(() => {
// 		// Update bounding box when the orientation changes
// 		// meshRef.material.flatShading = true;
// 	}, [props.stl]);

// 	return (
// 		<mesh ref={meshRef}>
// 			<primitive object={simplifiedGeometry} />
// 			<meshStandardMaterial
// 				color="#4e4e4e"
// 				roughness={0.2}
// 				flatShading={true}
// 				wireframe={true}
// 			/>
// 		</mesh>
// 	);
// };

const Geom = (props) => {
	const meshRef = useRef();
	const stl = useLoader(STLLoader, "/cureTool.stl"); // Load STL model

	// Create a SimplifyModifier instance
	const modifier = new SimplifyModifier();

	// Clone the original geometry
	const originalGeometry = stl.clone();

	// Log the count of triangles before simplification
	// console.log("Original Attr Count:", originalGeometry.attributes);
	// console.log(
	// 	"Original Vertices Count:",
	// 	originalGeometry.attributes.position.count
	// );

	// Modify the geometry to reduce the number of triangles
	// console.log("GGGGG-", originalGeometry);
	// console.log("STLLL-", stl);
	const targetTriangleCount = Math.floor(
		originalGeometry.attributes.position.count * 0.1
	);
	const tri = Math.floor(originalGeometry.attributes.position.count * 6 - 12);
	console.log("Orignal Triangle Count -", tri);
	// console.log("Total vertices - ", originalGeometry.attributes.position.count);
	console.log("target %reduce tri -", targetTriangleCount);
	const simplifiedGeometry = modifier.modify(
		originalGeometry,
		targetTriangleCount
	);

	// Log the count of triangles after simplification

	console.log(
		"Simplified Vertices Count:",
		simplifiedGeometry.attributes.position.count
	);

	// const handleDownload = () => {
	// 	const exporter = new STLExporter();

	// 	// Use the existing scene to export the geometry
	// 	const sceneForExport = new THREE.Scene();
	// 	sceneForExport.add(meshRef.current);

	// 	const data = exporter.parse(sceneForExport, { binary: true });
	// 	const blob = new Blob([data], { type: "application/octet-stream" });
	// 	const link = document.createElement("a");
	// 	link.href = URL.createObjectURL(blob);
	// 	link.download = "simplified_model.stl";
	// 	link.click();
	// 	URL.revokeObjectURL(link.href);
	// };
	const handleDownload = () => {
		// const exporter = new STLExporter();
		// // Clone the mesh to export the cloned model
		// const clonedMesh = meshRef.current.clone();
		// const clonedGeometry = clonedMesh.geometry.clone();
		// clonedMesh.geometry = clonedGeometry;
		// // Use the existing scene to export the cloned geometry
		// const sceneForExport = new THREE.Scene();
		// sceneForExport.add(clonedMesh);
		// const data = exporter.parse(sceneForExport, { binary: true });
		// const blob = new Blob([data], { type: "application/octet-stream" });
		// const link = document.createElement("a");
		// link.href = URL.createObjectURL(blob);
		// link.download = "simplified_model.stl";
		// link.click();
		// URL.revokeObjectURL(link.href);
	};

	useEffect(() => {
		// Update bounding box when the orientation changes
		// meshRef.material.flatShading = true;
		// console.log("gggg=-", meshRef.current.geometry);

		setTimeout(() => {
			handleDownload();
		}, 3000);
	}, [props.stl]);

	return (
		<mesh ref={meshRef}>
			<primitive object={simplifiedGeometry} />
			<meshStandardMaterial
				color="#4e4e4e"
				roughness={0.2}
				flatShading={true}
				wireframe={true}
			/>
		</mesh>
	);
};
//LIGHT GEOMETRY
const Bulb = (props) => {
	return (
		<mesh {...props}>
			{/* <pointLight castShadow /> */}
			<pointLight
				intensity={1}
				color="white"
				castShadow
			/>
			{/* takes radius in args */}
			{/* <sphereGeometry args={[10]} /> */}
			<meshPhongMaterial emissive="yellow" />
		</mesh>
	);
};

// FUNCTIONS
const CalculateBoundingBox = (stlfile) => {
	const val = stlfile.boundingBox;
	return val;
};

// RENDERER MAIN FUNCTION TO ENTER INTO THE STL RENDERER -------------------------------------------------
const Converter = (props) => {
	console.log(props.partName);
	const [pos, setPos] = useState([0, 0, 0]);
	const scale = 0.001;
	const [fullScreen, setFullScreen] = useState(false);

	return (
		<div className="modal">
			<div
				className="modal-content"
				style={{ position: `${fullScreen ? "static" : "relative"}` }}
			>
				<div className="canvas-main-container">
					<div className="canvas-icons-conainer">
						{!fullScreen ? (
							<MdOutlineFullscreen
								className="canvas-fullscreen-icon"
								size={30}
								color="white"
								onClick={() => setFullScreen(true)}
							/>
						) : (
							<MdFullscreenExit
								className="canvas-fullscreen-icon"
								size={30}
								color="white"
								onClick={() => setFullScreen(false)}
							/>
						)}
						<MdClose
							className="canvas-close-icon"
							onClick={props.onClose}
							size={30}
							color="white"
						/>
					</div>
					<p
						style={{
							zIndex: 1,
							position: "absolute",
							left: "0.6rem",
							top: "0.6rem",
							color: "black",
							background: "white",
							padding: "0.1rem 0.6rem",
							fontWeight: "500",
							fontSize: "1.4rem",
							opacity: "0.8",
						}}
					>
						{"Part Name - "}
						<span style={{ fontWeight: "400", opacity: "1", color: "gray" }}>
							{"  " + props.partName}
						</span>
					</p>

					<Canvas
						shadows
						style={{
							background: "var(--card-grey)",
						}}
						camera={{
							position: [100, 100, 100],
						}}
						pixelRatio={window.devicePixelRatio}
					>
						<Suspense fallback={<Loader />}>
							<Geom name={props.file} />
						</Suspense>

						<Bulb position={[200, 200, 200]} />
						<Bulb position={[-200, 200, -200]} />
						<Bulb position={[200, 200, -200]} />
						<Bulb position={[-200, 200, 200]} />
						<Bulb position={[-0, -300, 0]} />
						<ambientLight
							intensity={10}
							color={"#3e3e3e"}
						/>
						<CameraController />
					</Canvas>
					<div className="canvas-bottom">
						<p
							style={{
								position: "absolute",
								bottom: "0",
								right: "0rem",
								fontWeight: "500",
								opacity: "0.8",
								fontSize: "1.2rem",
								color: "black",
								background: "white",
								padding: "0.4rem",
							}}
						>
							Powered by Intech Additive Solutions Pvt Ltd
							{/* <button onClick={handleDownload}>Download Simplified STL</button> */}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Converter;
