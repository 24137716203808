import { NavLink } from "react-router-dom";
import { MdDashboard, MdSettings } from "react-icons/md";
import styles from "components/Sidebar/sidebar.module.css";
const appRoutes = [
	<NavLink
		className={({ isActive }) =>
			`${styles.navLink} ${isActive ? styles.active : null}`
		}
		to="/"
		data-tooltip-id="dashboard-tooltip"
		data-tooltip-delay-show="1000"
		data-tooltip-content="Dashboard"
		data-place="right"
	>
		<MdDashboard size={20} />
		<span className={styles.navLinkName}>Dashboard</span>
	</NavLink>,
	<NavLink
		className={({ isActive }) =>
			`${styles.navLink} ${isActive ? styles.active : null}`
		}
		to="/settings"
		data-tooltip-id="settings-tooltip"
		data-tooltip-delay-show="1000"
		data-tooltip-content="Settings"
		data-place="right"
	>
		<MdSettings size={20} />
		<span className={styles.navLinkName}>Settings</span>
	</NavLink>,
];

export default appRoutes;
