import { RequestStatus } from "Utils/constants";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
const initialState = {
	currentStep: 0,
	status: false,

	project: {
		project_name: "",
		id: uuidv4(),
		materials: [],
	},

	errors: [],

	drawer: {
		isDrawerOpen: false,
		isPopupOpen: false,
	},

	stepOneCardDeletingIds: [],

	folderOpen: true,
	InfoPopUpOpen: false,

	buildsLoadingDisable: {
		loading: false,
		hasError: false,
	},

	LoadingScreen: {
		isLoadingOpen: false,
		isLoading: false,
	},
};
const stepperSlice = createSlice({
	name: "stepper",
	initialState,
	reducers: {
		nextStep: (state) => {
			state.currentStep += 1;
		},

		prevStep: (state) => {
			state.currentStep -= 1;
			state.uploadingDisable = false;
		},

		setProjectName: (state, action) => {
			state.project.project_name = action.payload; // Update the job_name field with the provided value
		},

		setIsPopupOpen: (state, action) => {
			state.drawer.isPopupOpen = action.payload;
		},

		setIsInfoPopUpOpen: (state, action) => {
			state.InfoPopUpOpen = action.payload;
		},

		setIsDrawerOpen: (state, action) => {
			state.drawer.isDrawerOpen = action.payload;
		},

		clearStepper: (state) => {
			//delete the job from aws s3
			// deleteJobFromS3(state.job.id);
			const init = {
				...initialState,
				project: { ...initialState.project, id: uuidv4() },
			};

			return init; // Reset the state to the initial state
		},

		addCard: (state, action) => {
			const newCard = { id: action.payload.id };

			if (!state.project.materials) {
				state.project.materials = []; // Initialize the array if it's undefined
			}

			state.project.materials.push(newCard);
			state.errors[state.project.materials.length - 1] = {};
		},

		//If Id exists then remove it else add it
		toggleDeletingCardIds: (state, action) => {
			if (state.stepOneCardDeletingIds.includes(action.payload)) {
				state.stepOneCardDeletingIds.filter((id) => id !== action.payload);
			} else {
				state.stepOneCardDeletingIds.push(action.payload);
			}
		},

		deleteCard: (state, action) => {
			let index = -1;

			state.project.materials = state.project.materials.filter((material) => {
				index++;

				return material.id !== action.payload.id;
			});

			state.errors[index] = {};
		},

		selectOption: (state, action) => {
			const { cardId, name, value } = action.payload;

			const cardIndex = state.project.materials.findIndex(
				(card) => card.id === cardId
			);

			if (cardIndex !== -1) {
				state.project.materials[cardIndex][name] = value;
			}
		},

		addUploadedStlFiles: (state, action) => {
			const { cardId, partObj } = action.payload;
			const cardIndex = state.project.materials.findIndex(
				(card) => card.id === cardId
			);

			if (cardIndex !== -1) {
				console.log("statte----", cardIndex);

				if (!state.project.materials[cardIndex].parts) {
					state.project.materials[cardIndex].parts = []; // Initialize the array if it's undefined
				}

				// Add part object containing name, id and qty in the material card
				state.project.materials[cardIndex].parts.push(partObj);
			}
		},

		setUploadingDisable: (state, action) => {
			state.uploadingDisable = action.payload;
		},

		// STEP 2: REDUCERS FOR STEP 2
		addQuantity: (state, action) => {
			const { materialIndex, partIndex, quantity } = action.payload;

			if (quantity >= 1 && quantity <= 100000) {
				state.project.materials[materialIndex].parts[partIndex].qty = quantity;

				state.project.materials[materialIndex].parts[partIndex].error = "";
			} else if (quantity > 100000) {
				return;
			} else {
				state.project.materials[materialIndex].parts[partIndex].qty = "";

				state.project.materials[materialIndex].parts[partIndex].error =
					"please add qty atleast 1.";
			}

			// Disable Next on Quantity Error
			state.uploadingDisable = !state.project.materials
				.map((card) =>
					card.parts.map((part) =>
						part.error === "" || part.error === undefined ? true : false
					)
				)
				.map((ele) => ele.reduce((acc, curr) => acc && curr, true))
				.reduce((acc, curr) => acc && curr, true);
		},

		LoadiingScreen: (state, action) => {
			const { isLoading } = action.payload;

			state.LoadingScreen.isLoading = isLoading;
		},

		setApplication: (state, action) => {
			const { materialIndex, partIndex, application } = action.payload;

			state.project.materials[materialIndex].parts[partIndex].application =
				application;
		},

		deletePart: (state, action) => {
			const { partId, materialId } = action.payload;
			const { materials } = state.project;

			const materialIndex = materials.findIndex(
				(material) => materialId === material.id
			);
			const partIndex = materials
				.find((material) => material.id === materialId)
				.parts.findIndex((part) => part.id === partId);

			//Removing the part
			if (
				materials.find((material) => material.id === materialId).parts
					.length === 1
			) {
				materials.splice(materialIndex, 1);
			} else {
				materials[materialIndex].parts.splice(partIndex, 1);
			}

			// Disable Next on Deleting all parts
			state.uploadingDisable = Boolean(
				!(
					materials
						.map((card) =>
							card.parts.map((part) =>
								part.error === "" || part.error === undefined ? true : false
							)
						)
						.map((ele) => ele?.reduce((acc, curr) => acc && curr, true))
						.reduce((acc, curr) => acc && curr, true) &&
					materials
						.map((card) => card.parts.length)
						.reduce((acc, curr) => acc || curr, 0)
				)
			);
		},
		// STEP 2: END REDUCERS

		setErrors: (state, action) => {
			const { cardNumber, name, value, errorMessage } = action.payload;
			if (cardNumber === -1) {
				// Handle error for the job name
				state.projectNameError = errorMessage;
				return;
			}

			let errorText = "";
			if (!errorMessage) {
				//if not getting error message from user create message using errorMessageGenerator
				errorText = errorMessageGenerator(name, value, errorMessage);
			} else errorText = errorMessage;
			state.errors[cardNumber] = {
				...state.errors[cardNumber],
				[name]: errorText,
			};
		},
		setFolderOpen: (state, action) => {
			console.log("action folder open", action.payload);
			state.folderOpen = action.payload;
		},
	},

	extraReducers: (builder) => {
		//Get Projects Actions
		builder.addCase(postJob.pending, (state) => {
			state.projectsListStatus = RequestStatus.LOADING;
		});

		builder.addCase(postJob.fulfilled, (state, action) => {
			// state.projectsListStatus = RequestStatus.FULFILLED;
			// state.projectsList = action.payload;
			state.drawer.isDrawerOpen = false;
			// state.InfoPopUpOpen = true;
			state.InfoPopUpOpen = true;
		});

		builder.addCase(postJob.rejected, (state, action) => {
			// state.projectsListStatus = RequestStatus.ERROR;
			// state.projectListError = action.error.message;
		});
	},
});

export const {
	nextStep,
	prevStep,
	setProjectName,
	setIsPopupOpen,
	setIsInfoPopUpOpen,
	setIsDrawerOpen,
	clearStepper,
	addCard,
	toggleDeletingCardIds,
	deleteCard,
	selectOption,
	addUploadedStlFiles,
	setUploadingDisable,
	addQuantity,
	LoadiingScreen,
	setApplication,
	deletePart,
	setErrors,
	setFolderOpen,
} = stepperSlice.actions;
export default stepperSlice.reducer;

// UTILITY FUNCTIONS FOR STEPPER SLICE
const errorMessageGenerator = (name, value) => {
	// console.log("name--", name, value);
	if (name === "layer_thickness" && value === "") {
		return `Please select an option for Layer Thickness`;
	}
	if (value === "") {
		return `Please select an option for ${name}`;
	}
	return "";
};

// export const postJob = createAsyncThunk(
// 	"post/project",
// 	async (user_id, thunkAPI) => {
// 		const state = thunkAPI.getState();
// 		try {
// 			const statusValue = state.stepperSlice.currentStep === 2 ? true : false;
// 			console.log("Entered Thunk");
// 			const savingToastId = toast.info("Saving...", {
// 				autoClose: false,
// 				position: toast.POSITION.TOP_CENTER,
// 			});
// 			const res = await axios.post(
// 				"/api/v1/analysis",
// 				JSON.stringify({
// 					id: uuidv4(),
// 					name: state.stepperSlice.project.project_name,
// 					isBookmarked: false,
// 					pending: true,
// 					project: state.stepperSlice.project,
// 				}),
// 				{
// 					headers: { "Content-Type": "application/json" },
// 				}
// 			);

// 			toast.dismiss(savingToastId);
// 			toast.success(res.data.message, { position: toast.POSITION.TOP_CENTER });
// 			// toast.success(res.data.message, { position: toast.POSITION.TOP_CENTER });
// 			// toast.promise(res.data.message, {
// 			// 	pending: "Promise is pending",
// 			// 	success: "Promise resolved 👌",
// 			// 	error: "Promise rejected 🤯",
// 			// });
// 			thunkAPI.dispatch(clearStepper());
// 			// closePopup(); // Close the popup // setTimeout(() => { //     setIsOpen((prev) => !prev); // Open the drawer after a delay // }, 2000); // 2000 milliseconds delay (2 seconds)
// 		} catch (err) {
// 			console.log("thunk error", err.message);
// 			toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
// 			const { rejectWithValue } = thunkAPI;
// 			return rejectWithValue(err.response.data);
// 		}
// 	}
// );

export const postJob = createAsyncThunk(
	"post/project",
	async (user_id, thunkAPI) => {
		const state = thunkAPI.getState();
		try {
			const statusValue = state.stepperSlice.currentStep === 2 ? true : false;
			console.log("Entered Thunk");

			// Use toast.promise to handle the promise-based notification
			// await toast.promise(
			// new Promise(async (resolve, reject) => {

			try {
				const res = await axios.post(
					"/api/v1/compute",
					JSON.stringify({
						id: uuidv4(),
						name: state.stepperSlice.project.project_name,
						isBookmarked: false,
						pending: true,
						project: state.stepperSlice.project,
					}),
					{
						headers: { "Content-Type": "application/json" },
					}
				);
				// resolve(res.data.message); // Resolve the promise with the success message
				// thunkAPI.dispatch(setIsInfoPopUpOpen(true));
			} catch (err) {
				toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
				console.log("thunk error---", err.message);
				// reject(err.message); // Reject the promise with the error message
			}
			// }),
			// {
			// pending: "Saving...", // The message shown while the promise is pending
			// success: "Submitted Successfully", // The message shown when the promise is resolved successfully
			// error: "An error occurred", // The message shown when the promise is rejected with an error
			// position: toast.POSITION.TOP_CENTER,
			// }
			// );

			// thunkAPI.dispatch(clearStepper());
		} catch (err) {
			console.log("thunk error1", err.message);

			// Show error message using toast.error
			toast.error(err.message, { position: toast.POSITION.TOP_CENTER });

			const { rejectWithValue } = thunkAPI;
			return rejectWithValue(err.response.data);
		}
	}
);
