import { trimPartName } from "Utils/utils";
import styles from "./projectCard.module.css";
import Skeleton from "react-loading-skeleton";

const ProjectCard = ({ part, image }) => {
	return (
		<div className={styles.cardMainContainer}>
			<div className={styles.cardTopContainer}>
				{!image ? (
					<Skeleton
						height={"16rem"}
						width={"21rem"}
						borderRadius={"1rem"}
					/>
				) : (
					<img
						src={image}
						alt="snapshot"
					/>
				)}
			</div>
			<div className={styles.cardBottomContainer}>
				<h4>{trimPartName(part?.name, 25)}</h4>
				<p>{part?.materialName}</p>
			</div>
		</div>
	);
};

export default ProjectCard;
