import { Modal } from "components";
import styles from "./costTab.module.css";

import { Line } from "react-chartjs-2";
import { MdOutlineInfo } from "react-icons/md";
import { LuMaximize2 } from "react-icons/lu";
import Skeleton from "react-loading-skeleton";

export const CostTab = ({ recievedData, quantities }) => {
	const xLabels = recievedData?.conventionalData?.map((data) => data[1]);
	const yLabels = recievedData?.conventionalData?.map((data) => data[0]);

	const data = {
		// X-axis here
		labels: yLabels,

		datasets: [
			{
				label: "Conventional",
				data: xLabels,
				borderColor: "blue",
				backgroundColor: "rgba(0, 0, 255, 0.2)",

				pointRadius: (context) => {
					return context.chart.scales.x.ticks.some(
						(tick) => tick.label === context.dataIndex + 1
					)
						? 4
						: 1;
				},
				pointHoverRadius: 3,
				hitRadius: 10,
			},

			{
				label: "Additive",
				data: recievedData?.additiveData,
				borderColor: "green",
				backgroundColor: "rgba(0, 255, 0, 0.2)",

				pointRadius: (context) =>
					context.chart.scales.x.ticks.some(
						(tick) => tick.label === context.dataIndex + 1
					)
						? 3
						: 1,
				hitRadius: 10,
			},
		],
	};

	const options = {
		responsive: true,

		scales: {
			x: {
				beginAtZero: true,

				ticks: {
					min: 50,
					max: 190,
					stepSize: 10,
				},

				title: {
					display: true,
					text: "Quantity",
					color: "gray",

					font: {
						family: "Arial",
						size: 15,
						weight: "bold",
					},

					padding: { top: 0, left: 0, right: 0, bottom: 20 },
				},
			},

			y: {
				beginAtZero: true,

				title: {
					display: true,
					text: "Average cost",
					color: "gray",

					font: {
						family: "Arial",
						size: 15,
						weight: "bold",
					},

					padding: { top: 20, left: 0, right: 0, bottom: 0 },
				},
			},
		},

		plugins: {
			legend: {
				display: true,
				position: "bottom",
			},

			title: {
				display: true,
				text: "Conventional vs Additive printing cost",

				font: {
					family: "Arial",
					size: 20,
					weight: "bold",
				},
			},
		},
	};

	const MapComponent = () => {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					height: "100%",
				}}
			>
				{recievedData ? (
					quantities[0].quantity > 1 || quantities.length > 1 ? (
						<Line
							data={data}
							options={options}
						/>
					) : (
						<h2
							style={{
								textAlign: "center",
								width: "100%",
								color: "gray",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								opacity: "0.7",
								gap: "0.5rem",
							}}
						>
							<MdOutlineInfo />
							No Graph Available for Part Quantity 1.
						</h2>
					)
				) : (
					<Skeleton
						width={"70rem"}
						height={"40rem"}
					/>
				)}
			</div>
		);
	};

	return (
		<div className={styles.mapContainer}>
			{quantities[0].quantity > 1 || quantities.length > 1 ? (
				<Modal
					top="-10rem"
					height="90vh"
					modalButtonName={<LuMaximize2 />}
					className={styles.mapModalClass}
				>
					<MapComponent />
				</Modal>
			) : null}

			<MapComponent />
		</div>
	);
};
