import Skeleton from "react-loading-skeleton";
import styles from "./suitablitlyAnalysisTab.module.css";

export const SuitabilityAnalysisTab = ({ data }) => {
	// Example Data object recieved through props
	// const data? = {
	// 	class: "Printable",
	// 	dimensions: {
	// 		x: 12,
	// 		y: 24,
	// 		z: 36,
	// 	},
	// 	volume: 223,
	// 	thickness: 0.4,
	// 	holes: 50,
	// };

	return (
		<div className={styles.partCard}>
			<div className={styles.partInfo}>
				<div className={styles.infoRow}>
					<div>
						The Part is :{" "}
						<span
							style={{
								color: `${
									data?.class === "printable"
										? "green"
										: data?.class === "printable with modification"
										? "orange"
										: data?.class === "non-printable"
										? "red"
										: "black"
								}`,
								textTransform: "capitalize",
							}}
						>
							{data?.class ? data?.class : <Skeleton width={"15rem"} />}
						</span>
					</div>
				</div>

				<div className={styles.infoRow}>
					<div>
						The Dimensions of the part are :{" "}
						<span>
							{data?.dimensions.x ? (
								`${data?.dimensions.x} X ${data?.dimensions.y} X ${data?.dimensions.x}	
							mm`
							) : (
								<Skeleton width={"15rem"} />
							)}
						</span>
					</div>
				</div>

				<div className={styles.infoRow}>
					<div>
						The Volume of the part is :{" "}
						{data?.volume ? (
							<p>
								{data?.volume} mm<sup>3</sup>
							</p>
						) : (
							<Skeleton width={"15rem"} />
						)}
					</div>
				</div>

				<div className={styles.infoRow}>
					<div>
						The Suitability score for the part is :{" "}
						{data?.suitability_score ? (
							<span>{data?.suitability_score}</span>
						) : (
							<Skeleton width={"15rem"} />
						)}
					</div>
				</div>

				{/* <div className={styles.infoRow}>
					<div>
						The Minimum Wall Thickness of the part is :{" "}
						{data?.thickness ? (
							<span>{data?.thickness} mm</span>
						) : (
							<Skeleton width={"15rem"} />
						)}
					</div>
				</div> */}

				{/* <div className={styles.infoRow}>
					<div>
						The number of holes in the part is : <span>{data?.holes}</span>
					</div>
				</div> */}
			</div>
		</div>
	);
};
