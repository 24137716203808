import { useEffect, useState } from "react";
import styles from "./home.module.css";

// Library imports
import {
	MdAdd,
	MdBookmarkAdded,

	MdFileDownloadDone,
	MdRefresh,
	MdSearch,
	MdWatchLater,
} from "react-icons/md";

// Components
import { CustomBtn, Popup, LoadingScreen, InfoPopup } from "components";
import { HomeError } from "./HomeError/HomeError";
import Drawer from "./Drawer/Drawer";
import ProjectList from "./ProjectList/ProjectList";

//Skeleton loader imports
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Redux states
import { useDispatch, useSelector } from "react-redux";
import {
	clearStepper,
	setIsDrawerOpen,
	setIsPopupOpen,
} from "redux/Slice/StepperSlice";
import {
	deleteProject,
	fetchProjects,
	setSelectedJobToDelete,
} from "redux/Slice/ProjectSlice";

// ENUM Constants
import { RequestStatus } from "Utils/constants";

export const Home = () => {
	const dispatch = useDispatch();

	const [searchQuery, setSearchQuery] = useState("");
	const isPopupOpen = useSelector(
		(state) => state.stepperSlice.drawer.isPopupOpen
	);

	const InfoPopUpOpen = useSelector(
		(state) => state.stepperSlice.InfoPopUpOpen
	);
	const isDrawerOpen = useSelector(
		(state) => state.stepperSlice.drawer.isDrawerOpen
	);
	const isLoading = useSelector(
		(state) => state.stepperSlice.LoadingScreen.isLoading
	);

	const tabs = [
		{
			label: "Completed Projects",
		},
		{
			label: "Pending Projects",
		},
		{
			label: "Bookmarked",
		},
	];

	const [activeTab, setActiveTab] = useState(0);

	const handleSearchQuery = (e) => {
		setSearchQuery(e.target.value);
	};
	const openPopup = () => {
		dispatch(setIsPopupOpen(true));
	};
	const closePopup = () => {
		dispatch(setIsPopupOpen(false));
	};

	const toggleDrawer = () => {
		if (isDrawerOpen) {
			openPopup();
		} else {
			// if (isDrawerOpen) await deleteJobFromS3(projectid, userID); // Delete the job from S3
			// dispatch(clearStepper());
			dispatch(clearStepper());
			dispatch(setIsDrawerOpen(!isDrawerOpen));
		}
	};

	const handleCreateProjectBtn = () => {
		console.log("Click");
		dispatch(setIsDrawerOpen(!isDrawerOpen));
	};

	const handleSave = () => {
		closePopup();
		dispatch(clearStepper());
		dispatch(setIsDrawerOpen(!isDrawerOpen));
	};

	const handleCancel = () => {
		// Perform cancel logic here
		closePopup();
	};

	useEffect(() => {
		dispatch(fetchProjects());
	}, []);

	const handleRefresh = () => {
		dispatch(fetchProjects());
	};

	return (
		<div className={styles.homeMainContainer}>
			<div className={styles.topToolBarContainer}>
				<div className={styles.projectButtonContainer}>
					{/* Create Project Button */}
					<CustomBtn
						handleClick={handleCreateProjectBtn}
						buttonText="Create Project"
						width="15rem"
						buttonIcon={<MdAdd size={15} />}
						// color="white"
						padding="10px"
					/>

					{/* Create Project drawer */}
					<Drawer
						isDrawerOpen={isDrawerOpen}
						handleToggle={toggleDrawer}
					/>
				</div>

				{/* Popup */}
				{/* {InfoPopUpOpen && ( */}

				<InfoPopup
					heading={"Project Submitted Successfully"}
					message={
						"The project is available in pending status. Once it is finished, you will get an email with a link to the project's results."
					}
				/>
				{/* // )} */}

				<LoadingScreen isLoading={isLoading} />

				{isPopupOpen && (
					<Popup
						onClose={closePopup}
						// handleSave={handleSave}
						// handleCancel={handleCancel}
						// isSaving={isSaving}
						// isDeleting={isDeleting}
						heading={"Deleting Project"}
						message={
							"You have unsaved changes. Closing now will discard all progress."
						}
						button1Text={"Delete"}
						button2Text={"Cancel"}
						onButton1Click={handleSave}
						onButton2Click={handleCancel}
					/>
				)}
				{/* SEARCH BAR */}
				<div className={styles.searchBar}>
					<input
						type="text"
						placeholder="Search..."
						value={searchQuery}
						onChange={handleSearchQuery}
					/>
					<MdSearch
						color="grey"
						size={20}
					/>
				</div>
				<div className={styles.toolbar}>
					<div>{/* <MdList size={25} /> */}</div>
					<div>{/* <MdGridView size={25} /> */}</div>
				</div>
			</div>
			<div className={styles.tabsListContainer}>
				<div className={styles.tabListMainContainer}>
					<div className={styles.tabList}>
						{tabs.map((tab, index) => (
							<button
								key={index}
								onClick={() => setActiveTab(index)}
								style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
								className={index === activeTab ? styles.active : ""}
							>
								{tab.label === "Completed Projects" && (
									<MdFileDownloadDone
										size={20}
										color={`${index === activeTab ? "green" : ""}`}
									/>
								)}
								{tab.label === "Pending Projects" && (
									<MdWatchLater
										size={20}
										color={`${index === activeTab ? "orange" : ""}`}
									/>
								)}
								{tab.label === "Bookmarked" && (
									<MdBookmarkAdded
										size={20}
										color={`${index === activeTab ? "var(--bg-blue1)" : ""}`}
									/>
								)}
								{tab.label}
							</button>
						))}
						<div className={styles.reloadBtn}>
							<MdRefresh
								size={25}
								onClick={handleRefresh}
							/>
						</div>
					</div>

					<ProjectsListRenderer
						activeTab={activeTab}
						searchQuery={searchQuery}
					/>
				</div>
			</div>
		</div>
	);
};

const ProjectsListRenderer = ({ activeTab, searchQuery }) => {
	const dispatch = useDispatch();
	const {
		projectsList,
		projectsListStatus,
		// projectListError,
		selectedJobToDelete,
	} = useSelector((state) => state.projectSlice);

	//Project delete handler functions
	const handleDelete = () => {
		dispatch(deleteProject(selectedJobToDelete));
		dispatch(setSelectedJobToDelete(null));
	};

	const handleCancelDelete = () => {
		dispatch(setSelectedJobToDelete(null));
	};
	const closePopup = () => {
		dispatch(setSelectedJobToDelete(null));
	};

	//Search projects list
	const filteredProjectsList = projectsList?.filter((project) => {
		const name = project.name?.toLowerCase();
		const query = searchQuery?.toLowerCase();
		return name?.includes(query);
	});

	//Tabs content filtering using switch case
	const RenderProjectsList = ({ activeTab }) => {
		switch (activeTab) {
			case 0:
				if (
					projectsList?.filter((project) => project.pending === false).length >
					0
				) {
					// Search Results check

					if (
						filteredProjectsList
							?.filter((project) => project.pending === false)
							.map((project) => (
								<ProjectList
									key={project.id}
									project={project}
								/>
							)).length > 0
					) {
						return filteredProjectsList
							?.filter((project) => project.pending === false)
							.map((project) => (
								<ProjectList
									key={project.id}
									project={project}
								/>
							));
					}
					// No Search results for Completed Projects
					else {
						return (
							<h1 style={{ textAlign: "center", color: "gray" }}>
								No results found in Completed Projects!
							</h1>
						);
					}
				}
				// PLACEHOLDER TEXT
				else {
					return (
						<div className={styles.placeholderContainer}>
							<div>
								No Completed Projects to display, get started by adding a new
								Project!
							</div>
							<ol>
								<li>
									Create Project: Click the 'Create Project' button to begin.
								</li>
								<li>
									Add quantity: Specify the quantity or details of the Project.
								</li>
								<li>
									Review: Double-check the Project information for accuracy.
								</li>
							</ol>
						</div>
					);
				}

			case 1:
				if (
					projectsList?.filter((project) => project.pending === true).length > 0
				) {
					// Search Results check
					if (
						filteredProjectsList
							?.filter((project) => project.pending === true)
							.map((project) => (
								<ProjectList
									key={project.id}
									project={project}
								/>
							)).length > 0
					) {
						return filteredProjectsList
							?.filter((project) => project.pending === true)
							.map((project) => (
								<ProjectList
									key={project.id}
									project={project}
									pending
									error={project.error}
								/>
							));
					}
					// No Search Results placeholder
					else {
						return (
							<h1 style={{ textAlign: "center", color: "gray" }}>
								No results found in Pending Projects!
							</h1>
						);
					}
				}
				// Placeholder For Pending Projects
				else {
					return (
						<div className={styles.placeholderContainer}>
							<div>
								No Projects pending, get started by adding a new Project!
							</div>
							<ol>
								<li>
									Create Project: Click the 'Create Project' button to begin.
								</li>
								<li>
									Add quantity: Specify the quantity or details of the Project.
								</li>
								<li>
									Review: Double-check the Project information for accuracy.
								</li>
							</ol>
						</div>
					);
				}

			case 2:
				if (
					projectsList?.filter((project) => project.isBookmarked === true)
						.length > 0
				) {
					// Search Results check
					if (
						filteredProjectsList
							?.filter((project) => project.isBookmarked === true)
							.map((project) => (
								<ProjectList
									key={project.id}
									project={project}
								/>
							)).length > 0
					) {
						return filteredProjectsList
							?.filter((project) => project.isBookmarked === true)
							.map((project) => (
								<ProjectList
									key={project.id}
									project={project}
									pending={project.pending ? true : false}
								/>
							));
					}
					// No Search Results placeholder
					else {
						return (
							<h1 style={{ textAlign: "center", color: "gray" }}>
								No Bookmarked Projects found !
							</h1>
						);
					}
				}
				// Placeholder For No Bookmarked projects
				else {
					return (
						<div className={styles.placeholderContainer}>
							<div style={{ textAlign: "center" }}>
								Your bookmarked projects list is empty. Begin by selecting
								projects of interest and bookmarking them for quick access and
								organization.
							</div>
						</div>
					);
				}

			case RequestStatus.ERROR:
				return <HomeError />;

			default:
				return projectsList?.map((project) => (
					<ProjectList
						key={project.id}
						project={project}
					/>
				));
		}
	};

	//Page Component
	const ProjectsComponent = () => {
		if (projectsListStatus === RequestStatus.LOADING) {
			return (
				// Skeleton Loader component
				<div>
					<Skeleton
						height={"4.76rem"}
						count={7}
						style={{ marginBottom: "10px" }}
					/>
				</div>
			);
		} else if (projectsListStatus === RequestStatus.FULFILLED) {
			return <RenderProjectsList activeTab={activeTab} />;
		} else if (projectsListStatus === RequestStatus.ERROR) {
			//Show try again below tabs
			return <RenderProjectsList activeTab={RequestStatus.ERROR} />;
		}
	};

	//Popup Component
	const PopupComponent = () =>
		selectedJobToDelete && (
			<Popup
				onClose={closePopup}
				heading={"Delete Project"}
				message={"Do you want to delete this project?"}
				button1Text={"Delete"}
				button2Text={"Cancel"}
				onButton1Click={handleDelete}
				onButton2Click={handleCancelDelete}
			/>
		);

	return (
		<div className={styles.tabContentContainer}>
			<ProjectsComponent />

			<PopupComponent />
		</div>
	);
};
