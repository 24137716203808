import { useEffect, useState } from "react";
import styles from "./step1.module.css";
import StepOneCard from "./StepOneCard/StepOneCard";

// Redux import
import { useDispatch, useSelector } from "react-redux";
import { addCard, setErrors, setProjectName } from "redux/Slice/StepperSlice";

// Library imports
import { v4 as uuidv4 } from "uuid";
import { MdOutlineAddCircle } from "react-icons/md";

export const Step1 = () => {
	const [maxMaterialError, setMaxMaterialError] = useState(false);

	const dispatch = useDispatch();
	const cards = useSelector((state) => state.stepperSlice.project?.materials);
	const projectNameError = useSelector(
		(state) => state.stepperSlice.projectNameError
	);
	const projectName = useSelector((state) => state.stepperSlice?.project);
	const projectId = useSelector((state) => state.stepperSlice.project.id);
	const materialLength = useSelector(
		(state) => state.stepperSlice.project.materials
	);

	const handleInputChange = (event) => {
		const value = event.target.value;

		if (value.trim() === "") {
			dispatch(
				setErrors({
					cardNumber: -1, // Assuming the job name error is not related to a specific card
					name: "projectName",
					errorMessage: "Project Name can't be blank",
				})
			);
		} else {
			dispatch(
				setErrors({
					cardNumber: -1,
					name: "projectName",
					errorMessage: "", // Clear the error message if the job name is not empty
				})
			);
		}
		
		dispatch(setProjectName(value));
	};

	useEffect(() => {
		if (materialLength.length >= 5) {
			setMaxMaterialError(true);
		} else {
			setMaxMaterialError(false);
		}
	}, [materialLength]);

	const handleAddNewCard = () => {
		if (materialLength.length >= 5) {
			setMaxMaterialError(true);
			return;
		}
		const id = uuidv4();
		dispatch(addCard({ id: id }));
	};

	return (
		<div className={styles.step1RightContainer}>
			<div className={styles.step1CardContainer}>
				<div>
					<p
						style={{
							padding: "0px 0px 4px 2px",
							fontSize: "14px",
							fontWeight: "500",
						}}
					>
						Enter Project Name
					</p>
				</div>

				<div className={styles.jobContainer}>
					<div className={`${styles.field} ${styles.email}`}>
						<div className={styles.inputArea}>
							<input
								type="text"
								maxLength={50}
								value={projectName.project_name}
								placeholder="Enter Project Name"
								onChange={handleInputChange}
							/>
						</div>

						{projectNameError && (
							<div className="error-message">{projectNameError}</div>
						)}
					</div>
				</div>

				{cards &&
					cards.map((card, index) => {
						return (
							<StepOneCard
								key={index}
								id={card.id}
								cardNumber={index}
								projectId={projectId}
								materials={card.materials}
							/>
						);
					})}

				<button
					className={styles.addButton}
					onClick={handleAddNewCard}
					disabled={maxMaterialError}
					// disabled={cards.length === 3 ? true : false}
				>
					<div>
						<MdOutlineAddCircle size={20} />

						<p style={{ color: "white" }}>Add New Material</p>
					</div>
				</button>

				{maxMaterialError && (
					<div className="error-message">
						Maximum of five materials allowed.
					</div>
				)}
			</div>
		</div>
	);
};
