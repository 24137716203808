import axios from "../api/axios";
import Cookies from "universal-cookie";

function setSession(authResult) {
	// Set the time that the access token will expire at
	const cookies = new Cookies();
	const expiresAt = JSON.stringify(
		authResult.expires_in * 1000 + new Date().getTime()
	);

	// localStorage.setItem('access_token', authResult.access_token);
	// localStorage.setItem('id_token', authResult.id_token);
	// localStorage.setItem('expires_at', expiresAt);

	cookies.set("access_token", authResult.access_token);
	cookies.set("id_token", authResult.id_token);
	cookies.set("expires_at", expiresAt);

	if (authResult.refresh_token) {
		localStorage.setItem("refresh_token", authResult.refresh_token);
	}
}

export async function removeAllTokens() {
	const cookies = new Cookies();
	const keys = ["refresh_token", "expires_at", "access_token"];
	keys.forEach((key) => {
		console.log(key);
		cookies.remove(key, { path: "/", domain: "localhost", secure: true });
		// cookies.remove(key, { path: '/', domain: '.intechadditive.com', secure: true });
	});
}

export async function refreshToken() {
	const cookies = new Cookies();
	const options = {
		method: "POST",
		url: "https://intechadditive.us.auth0.com/oauth/token", // process.env.AUTH0_DOMAIN
		headers: { "content-type": "application/json" },
		data: {
			grant_type: "refresh_token",
			scope: "openid offline_access",
			audience: "https://intechadditive.us.auth0.com/api/v2/", // process.env.AUTH0_AUDIENCE
			client_id: "oGzTMNScy9VPMnst8cs1gOhxIh9c6PVQ", // process.env.AUTH0_CLIENT_ID // We are using standalone app's client ID
			refresh_token: cookies.get("refresh_token"),
		},
	};

	axios
		.post(options.url, options.data, { headers: options.headers })
		.then((response) => {
			setSession(response.data);
		});
}
