import styles from "./listOfParts.module.css";

//Redux imports
import { useDispatch, useSelector } from "react-redux";

//Library imports
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import ProjectCard from "pages/HomePage/ProjectCard/ProjectCard";
import { clearPartAnalysis } from "redux/Slice/PartAnalysisSlice";

const ListOfParts = ({ totalParts }) => {
	const { imagesArray } = useSelector((state) => state.projectAnalysisSlice);
	const { partName } = useSelector(
		(state) => state.partAnalysisSlice.partAnalysisData
	);

	const dispatch = useDispatch();

	return (
		<div
			style={{
				margin: " 1rem",
				borderRadius: "1rem",
				boxShadow:
					"0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
			}}
		>
			<div className={styles.cardsContainer}>
				{totalParts.current?.length
					? totalParts?.current?.map((part, index) => (
							<Link
								to={`parts-${part?.id}`}
								state={{ partName: part?.name }}
								onClick={() => {
									// If the part details in redux is same as part in redux then don't fetch else clear
									if (part.name !== partName) {
										dispatch(clearPartAnalysis());
									}
								}}
								name={part?.name}
								className={"link"}
								key={index}
							>
								<ProjectCard
									part={part}
									image={imagesArray[index]?.image}
								/>
							</Link>
					  ))
					: [0, 1, 2, 3, 4].map((ele) => <Skeleton height={"22rem"} />)}
			</div>
		</div>
	);
};

export default ListOfParts;
