import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "api/axios";
const projectAnalysisSlice = createSlice({
	name: "projectAnalysis",
	initialState: {
		projectAnalysisData: {},
		activeTab: 0,
	},
	reducers: {
		setActiveTab: (state, action) => {
			state.activeTab = action.payload;
		},
		clearProjectAnalysis: (state) => {
			state.projectAnalysisData = {};
		},
		setImagesArray: (state, action) => {
			state.imagesArray = action.payload;
		},
		setTotalParts: (state, action) => {
			state.totalParts = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProjectAnalysis.pending, (state) => {
			state.projectAnalysisLoading = true;
		});
		builder.addCase(fetchProjectAnalysis.fulfilled, (state, action) => {
			state.projectAnalysisLoading = false;
			state.projectAnalysisData = action.payload;
		});
		builder.addCase(fetchProjectAnalysis.rejected, (state, action) => {
			state.projectAnalysisLoading = false;
			state.projectAnalysisError = action.payload;
		});
	},
});

export const {
	setActiveTab,
	clearProjectAnalysis,
	setImagesArray,
	setTotalParts,
} = projectAnalysisSlice.actions;

export const fetchProjectAnalysis = createAsyncThunk(
	"projectAnalysis/get",
	async (projectID) => {
		try {
			const projectAnalysisJSON = await axios.get(
				`/api/v1/analysis/projects/${projectID}`
				// `http://localhost:4000/projectsAnalysisData/${projectID}`
			);
			console.log(projectAnalysisJSON);
			return projectAnalysisJSON.data.projectsAnalysisData;
			// return projectAnalysisJSON;
		} catch (err) {
			return err;
		}
	}
);

export default projectAnalysisSlice.reducer;
