import styles from "./step2.module.css";
import { trimPartName } from "Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete, MdErrorOutline } from "react-icons/md";
import {
	addQuantity,
	deletePart,
	setApplication,
	setUploadingDisable,
} from "redux/Slice/StepperSlice";
import { deleteSingleFileFromS3 } from "AWS/awsService";
import { useEffect } from "react";
export const Step2 = () => {
	const materials = useSelector(
		(state) => state.stepperSlice.project?.materials || []
	);

	const projectid = useSelector((state) => state.stepperSlice?.project?.id);
	const userID = useSelector((state) => state.authSlice?.user?.id);
	// const userID = "userID";
	const dispatch = useDispatch();

	// FUNCTIONS

	const handleQuantityChange = (event, materialIndex, partIndex) => {
		// const { value } = event.target;
		// const newQuantity = Number(value);
		dispatch(
			addQuantity({
				materialIndex,
				partIndex,
				quantity: +event.target.value,
			})
		);
	};

	// Function to handle application change for a part
	const handleApplicationChange = (event, materialIndex, partIndex) => {
		const { value } = event.target;
		dispatch(
			setApplication({
				materialIndex,
				partIndex,
				application: value,
			})
		);
	};

	const quantityOptions = [];
	for (let i = 1; i <= 10; i++) {
		quantityOptions.push(<option value={i}>{i}</option>);
	}
	useEffect(() => {
		//
		dispatch(
			setUploadingDisable(
				!(
					materials
						.map((card) =>
							card.parts.map((part) =>
								part.error === "" || part.error === undefined ? true : false
							)
						)
						.map((ele) => ele?.reduce((acc, curr) => acc && curr, true))
						?.reduce((acc, curr) => acc && curr, true) &&
					materials
						.map((card) => card.parts?.length)
						?.reduce((acc, curr) => acc || curr, 0)
				)
			)
		);
	}, []);

	return (
		<div className={styles.stepRightContainer}>
			<div className={styles.stepCardContainer}>
				<div className={styles.partListHeader}>
					<h2>List Of Parts</h2>
				</div>
				{materials
					.map((card) => card.parts?.length)
					?.reduce((acc, curr) => acc || curr, 0) ? (
					<table className={styles.stepTable}>
						<thead style={{ backgroundColor: "var(--card-grey)" }}>
							<tr>
								<th>Part Name</th>
								<th>Material Name</th>
								<th>Qty</th>
								<th>Application</th>
							</tr>
						</thead>
						<tbody style={{ backgroundColor: "var(--card-light-grey)" }}>
							{materials?.map((material, materialIndex) =>
								material.parts?.map((part, partindex) => (
									<tr key={part.id}>
										<td>{trimPartName(part.name, 30)}</td>
										<td
											style={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{material.material}
										</td>
										{/* <td className={styles.inputArea}> */}
										<td>
											{/* <select
												value={part.qty}
												onChange={(event) =>
													handleQuantityChange(event, materialIndex, partindex)
												}
											>
												{quantityOptions}
											</select> */}
											<input
												className={styles.inputQty}
												min={1}
												max={100000}
												type="number"
												maxLength={50}
												value={part.qty}
												placeholder="Enter Qty"
												onChange={(event) =>
													handleQuantityChange(event, materialIndex, partindex)
												}
											/>
											{part.error !== "" && (
												<p
													style={{
														display: "flex",
														justifyContent: "left",
														alignItems: "left",
													}}
													className={"error-message"}
												>
													{part.error}
												</p>
											)}
										</td>
										<td style={{ display: "flex" }}>
											<select
												value={part.application || ""}
												onChange={(event) =>
													handleApplicationChange(
														event,
														materialIndex,
														partindex
													)
												}
											>
												<option value="">Select Application</option>
												{/* Add application options here */}
												<option value="Aerospace">Aerospace</option>
												<option value="Medical and dental">
													Medical and dental
												</option>
												<option value="General engineering">
													General engineering
												</option>
												<option value="Tool and die">Tool and die</option>
												<option value="Education and R&D">
													Education and R&D
												</option>
												<option value="Defense">Defense</option>
												<option value="Space">Space</option>
												<option value="Other">Other</option>

												{/* Add more application options as needed */}
											</select>
											<MdDelete
												size={20}
												className={styles.MdDelete}
												style={{
													marginLeft: "7px",
													margin: "auto",
												}}
												onClick={() => {
													deleteSingleFileFromS3(
														projectid,
														material.id,
														part.name,
														userID
													);
													dispatch(
														deletePart({
															partId: part.id,
															materialId: material.id,
														})
													);
												}}
											/>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				) : (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							columnGap: "0.6rem",
							color: "gray",
							opacity: "0.8",
							fontSize: "2rem",
							height: "66.3vh",
						}}
					>
						<MdErrorOutline size={25} />
						Please Add some parts!
					</div>
				)}
			</div>
		</div>
		// </div>
	);
};
