import styles from "./spinnerLoader.module.css";

export const SpinnerLoader = ({ size = "24px", color = "#3498db" }) => {
	const loaderStyle = {
		"--size": size,
		"--border-width": `${Math.max(2, parseFloat(size) / 12)}px`,
		borderTopColor: color,
	};

	return <div className={styles.spinner} style={loaderStyle}></div>;
};
