import { useState } from "react";
import styles from "./projectAnalysisSummary.module.css";

// Redux imports
import { useSelector } from "react-redux";

// Components
import { ZoneMap, ZoneTableComponent } from "../ZoneComponents";
import DoughnutChart from "../DoughnutChart/DoughnutChart";

const ProjectAnalysisSummary = () => {
	const [selectedZone, setSelectedZone] = useState(-1);

	const { projectAnalysisData } = useSelector(
		(state) => state?.projectAnalysisSlice
	);
	const totalParts = projectAnalysisData?.materials?.flatMap(
		(material) => material.parts
	);

	const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

	//Data formatter for zone map
	const zoneSpecificData = (zoneIndex) => {
		return projectAnalysisData.materials.flatMap((material) =>
			material.parts
				.filter((part) => part.zone === zoneIndex)

				.map((part) => {
					return {
						id: part.id,
						name: part.name,
						qty: part.qty,
						complexity: part.complexity,
						material: material.name,
					};
				})
		);
	};

	const ZoneMapDetailsComponent = () => {
		switch (selectedZone) {
			case 0:
				return (
					<ZoneTableComponent
						zone={1}
						data={zoneSpecificData(1)}
					/>
				);

			case 1:
				return (
					<ZoneTableComponent
						zone={2}
						data={zoneSpecificData(2)}
					/>
				);

			case 2:
				return (
					<ZoneTableComponent
						zone={3}
						data={zoneSpecificData(3)}
					/>
				);

			case 3:
				return (
					<ZoneTableComponent
						zone={4}
						data={zoneSpecificData(4)}
					/>
				);

			// case 4:
			// 	return (
			// 		<ZoneTableComponent
			// 			zone={5}
			// 			data={zoneSpecificData(5)}
			// 		/>
			// 	);

			// case 5:
			// 	return (
			// 		<ZoneTableComponent
			// 			zone={6}
			// 			data={zoneSpecificData(6)}
			// 		/>
			// 	);

			// case 6:
			// 	return (
			// 		<ZoneTableComponent
			// 			zone={7}
			// 			data={zoneSpecificData(7)}
			// 		/>
			// 	);

			// case 7:
			// 	return (
			// 		<ZoneTableComponent
			// 			zone={8}
			// 			data={zoneSpecificData(8)}
			// 		/>
			// 	);

			// case 8:
			// 	return (
			// 		<ZoneTableComponent
			// 			zone={9}
			// 			data={zoneSpecificData(9)}
			// 		/>
			// 	);

			default:
				return (
					<p>
						The graph indicates zone in which a part may fall in additive
						manufacturing. High complexity and low quantity are a sweet spot for
						additive manufacturing. Low complexity and high volume are more
						suited for conventional manufacturing technologies. However,
						advancements in additive technologies are enabling use of this
						technology for production environments. Medium complexity and medium
						volume parts are now increasingly being manufactured using additive
						technology.
					</p>
				);
		}
	};

	//Takes color and returns that colored box
	const ColorBoxDiv = ({ color }) => {
		let backgroundColor = "";
		let borderColor = "";

		const colorAssigner = () => {
			if (color === "red") {
				backgroundColor = "#dd7a7f";
				borderColor = color;
			} else if (color === "green") {
				backgroundColor = "#6cbf52";
				borderColor = color;
			} else {
				backgroundColor = color;
				borderColor = "#f7e67f";
			}
		};

		colorAssigner();

		return (
			<div
				style={{
					width: "3rem",
					height: "3rem",
					background: backgroundColor,
					outline: `2px solid ${borderColor}`,
				}}
			></div>
		);
	};

	//Returns Number of Parts according to Suitability
	const PartsPercentageSuitable = ({ suitablityClass }) => {
		return (
			(projectAnalysisData?.materials?.flatMap((material) =>
				material.parts
					.filter((part) => part.class === suitablityClass)
					.map((part) => {
						return {
							name: part.name,
							qty: part.qty,
							complexity: part.complexity,
						};
					})
			)?.length /
				totalParts?.length) *
			100
		).toFixed(2);
	};

	return (
		<div>
			<div className={styles.zoneMapContainer}>
				<h2 style={{ gridColumn: "1/3" }}>Feasibility Chart</h2>

				<div className={styles.mapComponent}>
					{!isObjectEmpty(projectAnalysisData) && (
						<ZoneMap
							zoneSpecificData={zoneSpecificData}
							setSelectedZone={setSelectedZone}
							selectedZone={selectedZone}
						/>
					)}
				</div>

				<div className={styles.mapDetails}>
					<ZoneMapDetailsComponent />
				</div>
			</div>

			<div className={styles.doughnutChartContainer}>
				<h2>Suitablity Chart</h2>

				<div className={styles.mapComponent}>
					<div className={styles.doughnutDetails}>
						This chart shows the parts which are Suitable, Suitable with
						Modifications and Unsuitable
					</div>

					<div className={styles.doughnutContainer}>
						<div className={styles.doughnutLegends}>
							<div className={styles.doughnutLegendsLabel}>
								<ColorBoxDiv color={"green"} />
								Suitable :
								<span>
									{/* <PartsPercentageSuitable suitablityClass={"Suitable"} /> % */}
									<PartsPercentageSuitable suitablityClass={"3"} /> %
								</span>
							</div>

							<div className={styles.doughnutLegendsLabel}>
								<ColorBoxDiv color={"yellow"} />
								Suitable with Modifications :
								<span>
									{/* <PartsPercentageSuitable
										suitablityClass={"Suitable with Mods"}
									/>{" "} */}
									<PartsPercentageSuitable suitablityClass={"2"} /> %
								</span>
							</div>

							<div className={styles.doughnutLegendsLabel}>
								<ColorBoxDiv color={"red"} />
								Unsuitable :
								<span>
									{/* <PartsPercentageSuitable suitablityClass={"Unsuitable"} /> % */}
									<PartsPercentageSuitable suitablityClass={"1"} /> %
								</span>
							</div>
						</div>

						{!isObjectEmpty(projectAnalysisData) && (
							<DoughnutChart totalParts={totalParts} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectAnalysisSummary;
