import styles from "./zoneTableComponent.module.css";

import { Link, useNavigate } from "react-router-dom";

import { trimPartName } from "Utils/utils";

export const ZoneTableComponent = ({ data, zone }) => {
	const navigate = useNavigate();

	const TableRenderer = () => {
		const RenderTableComponent = () => {
			if (data.length === 0) {
				return <div className={styles.emptyZone}>No Parts in this zone!</div>;
			}

			return (
				<table className={styles.table}>
					<thead>
						<tr>
							<th>Part</th>
							<th>Quantity</th>
							<th>Complexity</th>
							<th>Material</th>
						</tr>
					</thead>

					<tbody>
						{data?.map((item, index) => (
							<tr
								key={index}
								onClick={() =>
									navigate(`parts-${item.id}`, { state: { tab: 3 } })
								}
							>
								{/* <Link to={`parts-${item.id}`}> */}
								<td>{trimPartName(item.name, 18)}</td>
								<td>{item.qty}</td>
								<td>{item.complexity}</td>
								<td>{item.material}</td>
								{/* </Link> */}
							</tr>
						))}
					</tbody>
				</table>
			);
		};

		const ZoneHeader = (zone) => {
			switch (zone) {
				case 1:
					return (
						<>
							High complexity and low quantity -
							<span>
								This is the ideal zone for additive manufacturing, where annual
								quantity is low and complexity is high.
							</span>
						</>
					);

				case 2:
					return (
						<>
							High complexity and Moderate quantity –
							<span>
								Parts in this zone can also be economical with AM, this zone
								should also be evaluated for both cost and operation benefits.
							</span>
						</>
					);

				case 3:
					return (
						<>
							High complexity and high quantity –
							<span>
								Parts in this zone could have challenges. Evaluation of supply
								chain benefits, just in time can be evaluated.
							</span>
						</>
					);

				case 4:
					return (
						<>
							Moderate complexity and low quantity –
							<span>
								Parts in this zone could sometimes be economical with AM
								considering supply chain benefits.
							</span>
						</>
					);

				case 5:
					return (
						<>
							Moderate complexity and Moderate quantity –
							<span>
								Parts in this zone should be critically evaluated, there could
								be some parts which make sense.
							</span>
						</>
					);

				case 6:
					return (
						<>
							Moderate complexity and high quantity –
							<span>
								Parts in this zone are typically out of economic scope for AM,
								if there are some challenges with some parts they could be
								evaluated.
							</span>
						</>
					);

				case 7:
					return (
						<>
							Low complexity and low volume –
							<span>
								Some parts may be suitable for AM given the benefits of supply
								chain and just in time, the cost benefits should be evaluated.
							</span>
						</>
					);

				case 8:
					return (
						<>
							Low complexity and moderate volume –
							<span>
								Parts here are typically not suitable for AM, there could be
								some exceptions but should have low focus.
							</span>
						</>
					);

				case 9:
					return (
						<>
							Low complexity and high volume –
							<span>
								Parts here are typically better suitable with conventional
								manufacturing.
							</span>
						</>
					);

				default:
					return;
			}
		};

		return (
			<>
				<h3 className={styles.zoneTableHeader}>
					Zone {zone} : {ZoneHeader(zone)}
				</h3>
				<RenderTableComponent />
			</>
		);
	};

	return <TableRenderer />;
};
