import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import styles from "./materialAnalysisTab.module.css";
import Skeleton from "react-loading-skeleton";

export const MaterialAnalysisTab = ({ data, materialData }) => {
	const headers = [
		"Comparison",
		"Conventional",
		"Intech Qualified",
		"Globally Available",
	];

	console.log(materialData);
	//  data : [
	// 	{
	// 		label: "Tensile Strength",
	// 		conventional: 690,
	// 		additive: 980,
	// 		global: 1000,
	// 	},
	// 	{ label: "Elongation", conventional: 30, additive: 40, global: 1000 },
	// 	{ label: "Hardness", conventional: 180, additive: 320, global: 1000 },
	// 	{ label: "Yield", conventional: 310, additive: 640, global: 1000 },
	// ];

	// materialData : { additive,global }

	const PercentDeviation = ({ rowIndex, colIndex }) => {
		const columnName = headers[colIndex].toLowerCase();

		//Updated deviation formula
		const deviation = (
			((data[rowIndex][columnName] - data[rowIndex].conventional) /
				data[rowIndex].conventional) *
			100
		).toFixed(1);

		return (
			<div
				style={{
					color: `${
						deviation > 0 ? "#16c784" : deviation < 0 ? "#ed545e" : "black"
					}`,
					fontWeight: "bold",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					fontSize: "1.2rem",
				}}
			>
				{deviation > 0 ? (
					<MdArrowDropUp size={20} />
				) : deviation < 0 ? (
					<MdArrowDropDown size={20} />
				) : null}
				{Math.abs(deviation)} %
			</div>
		);
	};

	const PlaceholderSkeleton = () => (
		<tr>
			<td>
				<Skeleton width={"15rem"} />
			</td>

			<td>
				<Skeleton width={"15rem"} />
			</td>

			<td>
				<Skeleton width={"15rem"} />
			</td>

			<td>
				<Skeleton width={"15rem"} />
			</td>
		</tr>
	);

	return (
		<div className={styles.mainContainer}>
			<table className={styles.materialAnalysisTable}>
				<thead>
					<tr>
						{headers?.map((header, index) => (
							<th key={index}>
								{index < 2 ? (
									header
								) : (
									<div className={styles.tableColumnHeader}>
										{header}
										<span>{materialData[header]}</span>
									</div>
								)}
							</th>
						))}
					</tr>
				</thead>

				<tbody style={{ textAlign: "center" }}>
					{data ? (
						data?.map((rowData, rowIndex) => (
							<tr key={rowIndex}>
								<td>{rowData.label}</td>

								{headers?.slice(1)?.map((header, colIndex) => (
									<td key={colIndex}>
										<div
											style={{
												display: "flex",
												justifyContent: "space-evenly",
												alignItems: "center",
											}}
										>
											<div>{rowData[header.toLowerCase()]}</div>

											{colIndex > 0 && (
												<PercentDeviation
													rowIndex={rowIndex}
													colIndex={colIndex + 1}
												/>
											)}
										</div>
									</td>
								))}
							</tr>
						))
					) : (
						<>
							<PlaceholderSkeleton />
							<PlaceholderSkeleton />
							<PlaceholderSkeleton />
							<PlaceholderSkeleton />
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};
