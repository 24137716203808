import { useState, useEffect } from "react";
import { Sidebar } from "components";
import myAxios from "api/axios";
import styles from "./rootLayout.module.css";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { clearUser, setUser } from "redux/Slice/AuthSlice";

// Library Imports
import { Outlet } from "react-router-dom";

export const RootLayout = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.authSlice);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await myAxios.get(`/api/v1/auth`);
        dispatch(setUser({ user: userData.data.response }));
      } catch (error) {
        console.log("xhr", error);
        if (error && error.status === 401) {
          dispatch(clearUser());
        } else {
          // Handle other errors
          // console.log("Error--:", error);
          //   dispatch(clearUser());
        }
      }
    };

    fetchUser();
  }, []);

  if (!isAuthenticated) {
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_AUTH_URL;
    }, 4000);
  }

  return isAuthenticated ? (
    <>
      <main className={show ? styles.spaceToggle : null}>
        <Sidebar show={show} setShow={setShow} />

        <Outlet />
      </main>
    </>
  ) : (
    <div className={styles.unauthorizedUser}>
      <h1>Error 401</h1>

      <h2>Unauthorized user detected. Redirecting to Login... </h2>
    </div>
  );
};
