import axios from "axios";
import Cookies from "universal-cookie";
import { refreshToken } from "../auth/auth.service";

// Check if the environment is development or production
const isDevelopment = process.env.NODE_ENV;

// Set BASE_URL accordingly
const BASE_URL =
	isDevelopment === "development"
	? "http://localhost:8012"
	: "https://dev-amexplorer.intechadditive.com";

const cookies = new Cookies();

const getAccessTokenFromBrowserCookie = () => {
	return cookies.get("access_token");
};

const myAxios = axios.create({
	baseURL: BASE_URL,
	headers: {
		Authorization: `Bearer ${getAccessTokenFromBrowserCookie()}`,
	},
});

myAxios.interceptors.response.use(
	async (response) => {
		if (response.status === 401) {
			await refreshToken();
		}
		return response;
	},
	async (error) => {
		// checking for token refresh
		if (
			["TOKEN_EXPIRED"].includes(error.code) ||
			error.message.includes("Token is expired")
		) {
			await refreshToken();
		}
		if (error.response && error.response.data) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error.message);
	}
);

export default myAxios;
