import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ totalParts }) => {
	const PartsNumberSuitable = (suitablityClass) => {
		return totalParts.filter((part) => part.class === suitablityClass).length;
	};
	// Chart data
	const data = {
		labels: ["Suitable", "Suitable With Mods", "Unsuitable"],
		datasets: [
			{
				// data: [10, 40, 30],
				data: [
					// PartsNumberSuitable("Suitable"),
					// PartsNumberSuitable("Suitable with Mods"),
					// PartsNumberSuitable("Unsuitable"),
					PartsNumberSuitable("3"),
					PartsNumberSuitable("2"),
					PartsNumberSuitable("1"),
				],
				backgroundColor: [
					"rgb(52,168,8,0.7)",
					"rgb(255,255,0,0.5)",
					"rgb(204,0,0,0.5)",
				],
				borderColor: ["green", "rgb(255,215,0,0.5)", "red"],
				hoverBackgroundColor: [
					"rgb(52,168,83)",
					"rgb(255,215,0)",
					"rgb(174,30,30,1)",
				],
			},
		],
		hoverOffset: 4,
	};

	// Get the total value of all sections
	const totalValue = data.datasets[0].data.reduce((acc, curr) => acc + curr, 0);

	// Chart options
	const options = {
		plugins: {
			legend: {
				display: false,
				// position: "bottom",
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const dataset = context.dataset;
						const value = dataset.data[context.dataIndex];
						const percentage = ((value / totalParts.length) * 100).toFixed(2);
						return `Number of Parts: ${value} (${percentage}%)`;
					},
				},
			},
			title: {
				align: "center",
				display: "true",
				text: `Total Parts: ${totalParts.length}`,
				font: {
					weight: "bold",
					size: "20rem",
				},
			},
		},
	};

	return (
		<div>
			<Doughnut
				data={data}
				options={options}
			/>
		</div>
	);
};

export default DoughnutChart;
