import { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./ProjectAnalysis.module.css";

// Library imports
import { MdArrowBack, MdAssessment, MdOutlineList } from "react-icons/md";
// import Skeleton from "react-loading-skeleton";

// Component imports
import ProjectAnalysisSummary from "./ProjectAnalysisSummary/ProjectAnalysisSummary";
import ListOfParts from "./ListOfParts/ListOfParts";

// Redux toolkit imports
import { useDispatch, useSelector } from "react-redux";
import {
	fetchProjectAnalysis,
	setActiveTab,
	setImagesArray,
} from "redux/Slice/ProjectAnalysisSlice";
import { clearPartAnalysis } from "redux/Slice/PartAnalysisSlice";

export const ProjectAnalysis = () => {
	const totalParts = useRef([]);
	const { projectID } = useParams();

	// Redux states
	const dispatch = useDispatch();
	const userID = useSelector((state) => state.authSlice?.user?.id);
	const { projectAnalysisData } = useSelector(
		(state) => state?.projectAnalysisSlice
	);
	const activeTab = useSelector(
		(state) => state.projectAnalysisSlice.activeTab
	);

	const tabs = [
		{
			label: "Project Analysis Summary",
		},
		{
			label: "List of Parts",
		},
	];

	// Image URL Fetching
	totalParts.current = projectAnalysisData?.materials?.flatMap((material) =>
		material.parts.map((part) => {
			return { ...part, materialId: material.id, materialName: material.name };
		})
	);

	useEffect(() => {
		dispatch(clearPartAnalysis());

		dispatch(fetchProjectAnalysis(projectID));

		// return () => dispatch(clearProjectAnalysis());
	}, []);

	//FETCH IMAGES CORRESPONDING TO PARTS
	useEffect(() => {
		const fetchImages = async () => {
			if (totalParts.current !== undefined) {
				const fetchedImages = await Promise.all(
					totalParts?.current?.map(async (part) => {
						try {
							let newPartName = part.name;

							if (part.name.endsWith(".stl")) {
								// Remove the ".stl" extension
								newPartName = part.name.slice(0, -4);
							}
							const regex = /\.(stl|stp|step)$/g;

							const response = await fetch(
								`https://am-explorer-advanced.s3.us-west-2.amazonaws.com/${userID}/project-${projectID}/material-${
									part.materialId
								}/snapshot/${newPartName.replace(regex, "")}.png`
							);

							const blob = await response.blob();

							return { partID: part.id, image: URL.createObjectURL(blob) };
						} catch (error) {
							console.error("Error fetching image:", error);

							return null;
						}
					})
				);

				dispatch(setImagesArray(fetchedImages));
			}
		};

		fetchImages();

		return () => dispatch(setImagesArray([]));
	}, [totalParts.current?.length]);

	console.log("TOTAL PARTS  -", totalParts.current);

	return (
		<div className={styles.analysisMainContainer}>
			{/* TABS LIST */}
			<div className={styles.tabsListContainer}>
				<div className={styles.tabListMainContainer}>
					<div className={styles.tabList}>
						{/* Back to Home */}
						<Link
							to={"/"}
							className={`${styles.backButton} link`}
							onClick={() => dispatch(setActiveTab(0))}
						>
							<MdArrowBack
								size={25}
								color="gray"
							/>
						</Link>

						{/* Tabs  */}
						{tabs.map((tab, index) => (
							<button
								key={index}
								onClick={() => dispatch(setActiveTab(index))}
								style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
								className={index === activeTab ? styles.active : ""}
							>
								{tab.label === "Project Analysis Summary" && (
									<MdAssessment
										size={20}
										color={`${index === activeTab ? "var(--bg-blue1)" : ""}`}
									/>
								)}

								{tab.label === "List of Parts" && (
									<MdOutlineList
										size={20}
										color={`${index === activeTab ? "var(--bg-blue1)" : ""}`}
									/>
								)}

								{tab.label}
							</button>
						))}
					</div>
				</div>
			</div>

			{/* TABS RENDERER  */}
			{activeTab === 0 ? (
				<ProjectAnalysisSummary />
			) : (
				<ListOfParts totalParts={totalParts} />
			)}
		</div>
	);
};
