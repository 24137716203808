import React from "react";
import styles from "./popup.module.css";
import { MdClose } from "react-icons/md";
export const Popup = ({
	onClose,
	heading,
	message,
	button1Text,
	button2Text,
	onButton1Click,
	onButton2Click,
}) => {
	return (
		<div className={styles["popup-container"]}>
			<div className={styles["popup-backdrop"]} />

			<div className={styles["popup-content"]}>
				<div
					style={{
						marginBottom: "1rem",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<h2>{heading}</h2>
					<button
						className={styles["popup-close-button"]}
						onClick={onClose}
						data-testid="close-button"
					>
						<MdClose size={20} />
					</button>
				</div>
				<div style={{ color: "grey" }}>
					<p>{message}</p>
				</div>
				<div className={styles["popup-buttons"]}>
					<button
						className={`${styles["popup-button"]} ${styles["primary"]}`}
						onClick={onButton1Click}
					>
						{button1Text}
					</button>
					<button
						className={`${styles["popup-button"]} ${styles["secondary"]}`}
						onClick={onButton2Click}
					>
						{button2Text}
					</button>
				</div>
			</div>
		</div>
	);
};
