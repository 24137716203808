import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import styles from "./generativeTab.module.css";

// Library Imports
import { MdOutline3DRotation, MdOutlineInfo } from "react-icons/md";
import Skeleton from "react-loading-skeleton";

//Component Imports
import { Modal, Switch } from "components";
import { getObjectUrlFromS3 } from "AWS/awsService";
import { PartRenderer } from "../PartRenderer/PartRenderer";
import { ViewerComponent } from "./APSViewer/ViewerComponent";
import { setLatticeData } from "redux/Slice/PartAnalysisSlice";
import axios from "axios";
import { getAccessTokenFromLambda, getApsAccessToken } from "api/viewer";

export const GenerativeTab = ({
  //  imageUrl
  data,
  // file,
}) => {
  // Part details related states
  //Redux states
  const name = useSelector(
    (state) => state.partAnalysisSlice.partAnalysisData?.overviewTab?.name,
  );
  const { materialId, partName } = useSelector(
    (state) => state.partAnalysisSlice?.partAnalysisData,
  );
  const userID = useSelector((state) => state.authSlice?.user?.id);

  // React States
  const { projectID } = useParams();
  const [files, setFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState();

  const onModelError = (error) => {
    console.error(error);
  };

  const onModelLoaded = (viewer) => {
    console.log("%cModel loaded Successfully!", "color: #c92a2a");
  };
  const viewerRef = useRef();

  // Viewer Related states
  const dispatch = useDispatch();
  const { latticeInfillData, topologyData } = useSelector(
    (state) => state.partAnalysisSlice,
  );

  // Regex to convert step to stl
  const regex = /\.(stl|stp|step)$/g;

  console.log("FILES --", files);

  const getImageUrl = (variantId) => {
    return imageUrl;
  };

  const ViewerContainer = ({ btnName = "Aps Viewer", extensions, panel }) => {
    return (
      <Modal
        modalButtonName={btnName}
        modalHeader={btnName}
        height="100vh"
        // top="-8rem"
        top="-13rem"
        width="100%"
        left="0rem"
      >
        <div className={styles.viewerContainer}>
          {/* Called like a function due to input tag and redux state */}
          {panel()}

          <div className={styles.viewerComponent}>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative", //To fit the viewer inside the container
              }}
              ref={viewerRef}
            >
              <ViewerComponent
                urn={
                  "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6aW50ZWNoLWV4cGxvcmVyL0NvcnJlY3RXYXZlZ3VpZGUuc3Rw"
                }
                viewerRef={viewerRef}
                modelGuid=""
                extensions={extensions}
                onModelLoaded={onModelLoaded}
                onModelError={onModelError}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const LatticePanel = () => {
    return (
      <div className={styles.viewerSidebarContainer}>
        <div className={styles.viewerSidebarHeader}>
          <span>Lattice Infill</span>
        </div>

        <div className={styles.viewerSidebarInputTop}>
          <label htmlFor="offset-value">
            Offset value for skin thickness (in mm)
          </label>

          <input
            name="offset-value"
            type="number"
            placeholder="Input the value"
            min="0"
            value={latticeInfillData.offsetValue}
            onChange={(e) =>
              dispatch(
                setLatticeData({ type: "offset", value: +e.target.value }),
              )
            }
          />

          <label htmlFor="mesh-type">
            Select a mesh type you want to fill in
          </label>

          <select
            name="mesh-type"
            value={latticeInfillData.meshType}
            onChange={(e) =>
              dispatch(setLatticeData({ type: "mesh", value: e.target.value }))
            }
          >
            <option value={"Tetrahedral"}>Tetrahedral</option>
            <option value={"Dodecahedron"}>Dodecahedron</option>
            <option value={"Auxetic"}>Auxetic</option>
            <option value={"Star Tetrahedral"}>Star Tetrahedral</option>
            <option value={"Face Centered Cubic"}>Face Centered Cubic</option>
            <option value={"Body Centered Cubic"}>Body Centered Cubic</option>
            <option value={"Custom"}>Custom</option>
          </select>
        </div>

        {/*TODO: add apit call fn*/}
        <div className={styles.viewerSidebarInputBottom}>
          <button>START</button>
        </div>
      </div>
    );
  };

  const TopologyPanel = () => {
    const [selectionToggle, setSelectionToggle] = useState(false);

    const onSelect = () => {
      const viewer = window?.asp_viewer;
      const controller = viewer?.toolController;

      // Toggle extension mode
      if (selectionToggle) {
        controller.deactivateTool("draw-bounds-tool");
      } else {
        controller.activateTool("draw-bounds-tool");
      }

      setSelectionToggle((prev) => !prev);
      console.log(controller);
    };

    const downloadFile = async () => {
      try {
        const token = await getAccessTokenFromLambda();

        const downloadResponse = await axios.get(
          // `https://developer.api.autodesk.com/oss/v2/buckets/intech-explorer/objects/CorrectWaveguide.stp`,
          `https://developer.api.autodesk.com/oss/v2/buckets/intech-explorer/objects/Horn.stl`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          },
        );

        console.log(downloadResponse);

        const blob = new Blob([downloadResponse.data], {
          type: "application/octet-stream",
        });

        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "your_downloaded_file.stl"; // Set the desired file name

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <div
        className={`${styles.viewerSidebarContainer} ${styles.topologyPanel}`}
      >
        <div className={styles.viewerSidebarHeader}>
          <span>Topology Optimization</span>
        </div>

        <div className={styles.viewerSidebarInputTop}>
          <label htmlFor="offset-value">
            Force applied to surface with direction
          </label>

          <input
            name="offset-value"
            type="number"
            placeholder="Force with direction"
            min="0"
            value={latticeInfillData.offsetValue}
            onChange={(e) =>
              dispatch(
                setLatticeData({ type: "offset", value: +e.target.value }),
              )
            }
          />
        </div>

        <div className={styles.viewerSidebarInputBottom}>
          {/* Toggle Switch component */}
          <Switch
            labelOn={"Surface Selection On"}
            labelOff={"Surface Selection Off"}
            onClick={onSelect}
          />

          <button>Start Optimization</button>
          <button id="exportButton" onClick={downloadFile}>
            Export
          </button>
          <button>Reset</button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const paths = data?.map(
      (variant) =>
        `${userID}/project-${projectID}/material-${materialId}/Generative Design/` +
        partName.replace(".stp", "") +
        "/" +
        variant.variantId +
        ".stl",
    );

    const fetchPartLink = async () => {
      //Replace to fetch stl of stp files
      paths?.forEach(async (path) => {
        const partLink = await getObjectUrlFromS3(path);
        setFiles((prev) => [...prev, partLink]);
        console.log("render Link -----", partLink);
      });
    };

    fetchPartLink();
    return () => setFiles([]);

    // Included userID because on reload it remains undefined
  }, [materialId, partName, userID]);

  useEffect(() => {
    // Fetch the image URL when the component mounts
    async function fetchImage() {
      const imageKey = `${userID}/project-${projectID}/material-${materialId}/snapshot/${partName?.replace(
        regex,
        "",
      )}.png`;
      // const imageKey = `userId/project-9000e2be-5910-4733-bb8a-5c86c205be2c/material-a5f138b0-0466-44f9-8bf4-3c45e069b946/snapshot/CONFIDENTIAL_rotor mod.stl.png`;

      const url = await getObjectUrlFromS3(imageKey);
      setImageUrl(url);
    }
    fetchImage();
    return () => setImageUrl(null);

    // Included userID because on reload it remains undefined
  }, [materialId, partName, userID]);

  return (
    <>
      <div className={styles.tabList}>
        <button
          className={styles.tabButton}
          style={{
            padding: "0 1rem",
            cursor: "pointer",
            outline: "none",
            border: "none",
            borderRadius: "1.5rem",
            boxShadow: "0 0 1px 0 gray",
            height: "100%",
          }}
        >
          {"Dfam"}
        </button>

        <ViewerContainer
          btnName="Lattice Infill"
          extensions={["Autodesk.DocumentBrowser"]}
          panel={LatticePanel}
        />

        <ViewerContainer
          btnName="Topology"
          extensions={["DrawBoundsToolExtension", "Autodesk.DocumentBrowser"]}
          panel={TopologyPanel}
        />
      </div>

      {data ? (
        data.length ? (
          <div className={styles.generativeCardsContainer}>
            {data
              .slice() // Create a shallow copy of the array

              .sort((a, b) => b.suitabilityScore - a.suitabilityScore)

              .map((variant, index) => (
                <GenerativeCard
                  key={variant.variantId}
                  variant={variant}
                  imageUrl={getImageUrl(variant.variantId)}
                  partName={`${name}_${index + 1}`}
                  file={files[index]}
                />
              ))}
          </div>
        ) : (
          <h2
            style={{
              textAlign: "center",
              width: "100%",
              color: "gray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: "0.7",
              gap: "0.5rem",
            }}
          >
            <MdOutlineInfo />
            No Variants Generated for Selected Part.
          </h2>
        )
      ) : (
        <div className={styles.generativeCardsContainer}>
          <GenerativeCard />

          <GenerativeCard />

          <GenerativeCard />
        </div>
      )}
    </>
  );
};

const GenerativeCard = ({ variant, imageUrl, partName, file }) => {
  const [partModal, setShowPartModal] = useState(false);

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageError(true);
  };

  const handlePartModalToggle = (part) => {
    setShowPartModal(!partModal);
  };

  return (
    <div className={styles.cardContainer}>
      {!isImageLoaded && !isImageError && (
        <Skeleton
          height={"17rem"}
          borderRadius={"1rem"}
          baseColor="#b8c2cc"
          highlightColor="#d3dfeb"
        />
      )}

      <div className={styles.imgContainer}>
        <img
          src={imageUrl}
          alt="Snapshot"
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{
            display: isImageLoaded || isImageError ? "block" : "none",
          }}
        />
      </div>

      <p>
        Suitability Score :{" "}
        <span style={{ paddingLeft: "5px", color: "black" }}>
          {variant ? (
            <>{(Number(variant.suitabilityScore) * 100).toFixed(3)} %</>
          ) : (
            <Skeleton width={"7rem"} />
          )}
        </span>
        <MdOutline3DRotation
          className={styles.renderIcon}
          onClick={handlePartModalToggle}
          size={30}
          color="black"
        />
        <div>
          {partModal && (
            <PartRenderer
              partName={partName}
              file={file}
              onClose={handlePartModalToggle}
            />
          )}
        </div>
      </p>
    </div>
  );
};
